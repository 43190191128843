import Login from '@app/auth/components/Login'
import EnvTag from '@app/components/molecules/EnvTag'
import Drawer from '@app/components/organisms/Drawer'
import { FBREF_COURSE_EVALUATION } from '@app/evaluation-core/refs'
import { CourseEvaluation } from '@app/evaluation-core/types'
import Features from '@app/features'
import { State } from '@app/state/types'
import useInvite from '@common/hooks/useInvite'
import useUser from '@common/hooks/useUser'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  DefaultTheme,
  NavigationContainer,
  NavigationContainerRef,
  Route,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase'
import Map from '../map/components/Map'

import Profile from '../profile/components/Profile'
import { Settings } from '../settings'
import { SignUp } from '../signup'
import { Slope } from '../slope'
import { screenOptions } from './config'
import CoursesStack, { CoursesEvaluationTab } from './CoursesStack'
import { getActiveRouteName } from './helpers'
import CreateRatingPage from '../pages/CreateRatingPage'
import theme from '@app/ui/theme'
import Support from '../pages/Support'
import { AccountType } from '@app/auth/types'
import AdminPage from '../pages/AdminPage/AdminPage'
import AdminEvaluationsPage from '../pages/AdminEvaluationsPage/AdminEvaluationsPage'
import { SearchContainer } from '@app/evaluation/components/SearchInvitableRater.styles'
import SearchOverview from '../pages/SearchOverview/SearchOverview'

const linking = {
  prefixes: ['https://mychat.com', 'mychat://'],
  config: {
    screens: {
      Home: '',
      Profile: ':id/profile',
      Invitation: 'invitation/:evaluationId/:userId/:courseId/:newUser',
      Features: 'internal/features',
    },
  },
}

let haveRan = false

const Invitation = () => {
  const route = useRoute<any>()
  const navigation = useNavigation()
  const evaluationId = route.params?.evaluationId
  const userId = route.params?.userId
  const courseId = route.params?.courseId

  const { setInvite } = useInvite()

  const currentUser = useUser()

  useFirebaseConnect([{ path: FBREF_COURSE_EVALUATION(evaluationId) }])
  useFirestoreConnect([
    {
      collection: 'courses',
    },
    { collection: 'clubs', where: ['districtCountry', '==', 'Sweden'] },
  ])

  const evaluation: CourseEvaluation = useSelector(
    ({ firebase: { data } }: State) =>
      data.evaluations && data.evaluations[evaluationId]
  )

  useEffect(() => {
    if (evaluationId && evaluation && !haveRan) {
      haveRan = true
      setInvite({
        evaluationId,
        userId,
        email: evaluation?.users[userId]?.view?.email,
        courseId,
      })
    }
  }, [route.params, evaluation])

  const userExistsInEval =
    evaluation?.users[userId] || evaluation?.users[currentUser.id]

  if (!evaluation || !userId || !userExistsInEval) {
    navigate('Courses')
    return <></>
  }
  return <Login prefilledEmail={evaluation?.users[userId]?.view?.email} />
}

const Tab = createBottomTabNavigator()

export const navigationRef = React.createRef<NavigationContainerRef>()

export function navigate(name: string, params?: any) {
  navigationRef.current?.navigate(name, params)
}

export function goBack() {
  navigationRef.current?.goBack()
}

export function getCurrentRouteName(): string | undefined {
  return navigationRef?.current?.getCurrentRoute()?.name
}

const App: React.FC = () => {
  const [activeTab, setTab] = useState('Courses')
  const [flow, setFlow] = useState<'login' | 'courses'>()
  const user = useUser()

  useEffect(() => {
    if (user?.id) {
      return setFlow('courses')
    }
    return setFlow('login')
  }, [user])

  return (
    <>
      <View style={{ flex: 1, flexDirection: 'row', position: 'relative' }}>
        {activeTab === 'Courses' && <EnvTag />}

        <NavigationContainer
          linking={linking}
          ref={navigationRef}
          onStateChange={(state) => {
            if (!state) return
            setTab(getActiveRouteName(state))
          }}
          theme={{
            ...DefaultTheme,
            colors: {
              ...DefaultTheme.colors,
              background: theme.colors.background,
            },
          }}
        >
          <Drawer navigate={navigate} currentRoute={activeTab} />

          <Tab.Navigator tabBar={() => null}>
            {flow === 'courses' ? (
              <>
                <Tab.Screen name="Courses" component={CoursesStack} />
                <Tab.Screen name="SearchOverview" component={SearchOverview} />
                <Tab.Screen
                  name="CourseEvaluation"
                  component={CoursesEvaluationTab}
                />
                <Tab.Screen name="Map" component={Map} />
                <Tab.Screen name="Teams" component={Profile} />
                {(user.accountType === AccountType.SGFTeamLeader ||
                  user.accountType === AccountType.Interal) && (
                  <Tab.Screen name="Support" component={Support} />
                )}
                {user.isAdmin && (
                  <Tab.Screen
                    name="CreateSlopeTable"
                    component={Slope}
                    options={{ title: 'Create Slope Tables', ...screenOptions }}
                  />
                )}
                {user.isAdmin && (
                  <Tab.Screen
                    name="Admin"
                    component={AdminPage}
                    options={{ title: 'Admin', ...screenOptions }}
                  />
                )}
                {user.isAdmin && (
                  <Tab.Screen
                    name="AdminEvaluations"
                    component={AdminEvaluationsPage}
                    options={{ title: 'Admin evaluations', ...screenOptions }}
                  />
                )}
                <Tab.Screen name="Settings" component={Settings} />
                <Tab.Screen name="Invitation" component={Invitation} />
              </>
            ) : (
              <>
                <Tab.Screen name="Login" component={Login} />
                <Tab.Screen name="SignUp" component={SignUp} />
                <Tab.Screen name="Invitation" component={Invitation} />
              </>
            )}
            <Tab.Screen name="Features" component={Features} />
          </Tab.Navigator>
        </NavigationContainer>
      </View>
    </>
  )
}

export default App

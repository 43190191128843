export const CSV_COLUMNS = [
  'Club Name',
  'Club ID',
  'District',
  'Region',
  'Course Name',
  'Rating ID',
  'Rating Name',
  'Status',
  'Manual Version',
  'Rating Date',
  'Finalized Date',
  'Description',
]

export const STATUS_LABELS = [
  { key: 0, label: 'Not Started' },
  { key: 1, label: 'Preparing' },
  { key: 2, label: 'In Progress' },
  { key: 3, label: 'Finalized' },
]

export const MANUAL_VERSIONS = ['2020', '2024', 'Unknown']

export const UNGROUPED_CLUB_NAME = 'UNGROUPED'
export const UNGROUPED_COURSE_ID = 'ungrouped-course'
export const UNGROUPED_COURSE_NAME = 'Ungrouped Ratings'

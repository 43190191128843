import theme from '@app/ui/theme'
import S from 'styled-components/native'
import styled, { keyframes } from 'styled-components'

export const AdmContainer = S.View<{
  isTabletOrMobile?: boolean
  isSkeleton?: boolean
}>`
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: ${(props) => (props.isTabletOrMobile ? 'column' : 'row')};
    padding: ${(props) => (props.isSkeleton ? 16 : 0)}px;
    align-items: flex-start;
    overflow: auto;
    height: calc(100% - 90px);

`

export const AdmSectionMain = S.View<{ isTabletOrMobile?: boolean }>`
    flex: 1;
    height: 100%;
    width: 100%;
    ${(props) =>
      !props.isTabletOrMobile &&
      `
        border-right-width: 1px;
        border-right-color: ${theme.colors.light};
        overflow: auto;
        align-items: center;
    `};
`

export const AdmSectionSideSm = styled.div`
  padding-right: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom-width: 1px;
  border-bottom-color: ${theme.colors.light};
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
`

export const AdmSectionSide = S.View`
  width: 300px;
  height: 100%;
  margin-right: 30px;
  overflow-y: scroll;
`

export const AdmSectionSideLg = S.View`
  width: 400px;
  height: 100%;
  margin-right: 30px;
  overflow-y: scroll;
`

export const AdmSectionSideHeader = S.View`
    width: 100%;
    padding: 20px;
    padding-top: 40px;
`

export const AdmSectionSideItem = S.View`
    padding: 22px;
    padding-bottom: 10px;
    width: 100%;

    
`

export const AdmSectionSeparator = S.View`
    background-color: ${theme.colors.light};
    height: 1px;
    display: none;
`

export const AdmSectionSideItemBox = S.View`
    min-width: 260px;

`

export const AdmSectionHeading = S.Text`
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
    opacity: 0.8;
    letter-spacing: 0.6px;
`

export const AdmSectionSubText = S.Text`
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-size: 12px;
    opacity: 0.8;
    letter-spacing: 0.6px;
`

export const AdmSectionMainHeader = S.View`
    padding: 20px;
    padding-top: 40px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
`

export const AdmSectionMainContent = S.View`
    padding-top: 24px;
    width: 100%;
`

export const AdmSectionMainBottom = S.View`
    height: 50px;
    width: 100px;
    background-color: black;
`

export const Label = S.View`
    width: 100%;
    margin-bottom: 16px;
`

export const LabelText = S.Text`
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-size: 16px;
    opacity: 0.7;
`

export const DisabledAdjustmentText = S.Text`
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    opacity: 0.7;
    max-width: 800px;
    margin: 0 auto;
`

export const ResetAdjustmentButton = styled.button`
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 2px 6px 2px;
  display: flex;
  min-width: 30px;
  flex-direction: column;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  gap: 2px;
  width: 100%;
  height: 100%;
  align-content: center;
  border-radius: 7px;
`
export const ResetAdjustmentButtonContent = styled.div`
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`

import Spacer from '@app/components/atoms/Spacer'
import getEnv from '@app/environments/getEnv'
import { updateEvaluationDate } from '@app/evaluation-core/evaluation'
import { Eval, Gender, Golfer, MITee } from '@app/evaluation-core/types'
import { useSnackbar } from '@app/snackbar'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useUser from '@common/hooks/useUser'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import FoundationIcon from '@ovaeasy/react-native-vector-icons/Foundation'
import React, { useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import {
  AlertDescription,
  AlertIconContainer,
  AlertList,
  AlertListItem,
  AlertListText,
  AlertTitle,
  CourseLengthErrorContainer,
  CourseLengthWarningContainer,
  FormExGenderBtn,
  InnerTeeContianer,
  MenuDiv,
  MenuItem,
  TeeButton,
  TeeContainer,
  TitleText,
} from './styled'
import { useCourseLengthCheck } from '@common/hooks/useCourseLengthCheck'
import { getMinCourseLength } from '@common/utils/getMinCourseLength'
type FormURLObject = {
  evaluation: Eval
  genderSuffix: string
  tee: string
  courseId?: number
  clubId?: number
  golfer?: Golfer
  date?: number
}

type ExportFormItem = {
  title: string
  formUrl: ({
    evaluation,
    genderSuffix,
    tee,
    courseId,
    golfer,
    date,
    clubId,
  }: FormURLObject) => string
}

const BASE_URL_TMP = getEnv().api + '/ratings'

const getBaseURL = (manualVersion: string): string => {
  let ret = BASE_URL_TMP
  if (manualVersion !== '2020') ret += '/v2'
  return ret
}

const getExportFormItems = (
  evaluation: Eval,
  manualVersion: string
): ExportFormItem[] => {
  if (manualVersion === '2020')
    return [
      {
        title: 'Form 1-1',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/1/1/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 1-2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/1/2/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/2/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 3',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/3/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Slope Tables',
        formUrl: ({
          evaluation: Eval,
          genderSuffix,
          tee,
          courseId,
          date,
          clubId,
        }) =>
          `${getBaseURL(manualVersion)}/forms/slopetables/${
            evaluation.id
          }/${genderSuffix}/?clubId=${clubId}&date=${date}`,
      },
    ]
  else {
    return [
      {
        title: 'Form 1-1',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/1/1/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 1-2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/1/2/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/2/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 3',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/3/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Slope Tables',
        formUrl: ({ evaluation, genderSuffix, tee, courseId, date, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/slopetables/${
            evaluation.id
          }/${genderSuffix}/?clubId=${clubId}&date=${date}&holeIds=${
            evaluation.holeIds
          }`,
      },
    ]
  }
}

const FormItem = ({
  item,
  tee,
  indexOfTee,
  startDate,
  disabled = false,
}: {
  item: ExportFormItem
  tee: MITee
  indexOfTee: number
  startDate: Date
  disabled?: boolean
}) => {
  const [openSnackbar] = useSnackbar()
  const { currentId, courseId, evaluation } = useEvaluation()
  const checkForValues = (gender: Gender) => {
    return tee.golfers.some((item) => item.includes(gender))
  }

  if (item.title === 'Slope Tables') {
    return (
      <MenuItem>
        <FormExGenderBtn>
          <span style={{ fontWeight: 'bold', fontSize: 20 }}>{item.title}</span>
          <div style={{ display: 'flex', opacity: disabled ? '60%' : '100%' }}>
            <TouchableOpacity
              disabled={disabled}
              onPress={() =>
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.MEN,
                    tee: '0',
                    courseId,
                    date: startDate.getTime(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            >
              <FoundationIcon name="male-symbol" size={40} color={'#000'} />
            </TouchableOpacity>
            <Spacer width={15} />
            <TouchableOpacity
              disabled={disabled}
              onPress={() =>
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.WOMEN,
                    tee: '0',
                    courseId,
                    date: startDate.getTime(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            >
              <FoundationIcon name="female-symbol" size={40} color={'#000'} />
            </TouchableOpacity>
          </div>
        </FormExGenderBtn>
      </MenuItem>
    )
  }

  return (
    <MenuItem disabled={disabled}>
      <FormExGenderBtn>
        <TitleText>{item.title}</TitleText>
        <div
          style={{
            display: 'flex',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (disabled) {
                openSnackbar(
                  'The combined length of this tee is less then 1500yd, please move tees in LiveCR'
                )
                return
              }

              if (checkForValues(Gender.MEN)) {
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.MEN,
                    tee: indexOfTee.toString(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            }}
          >
            <FoundationIcon
              name="male-symbol"
              size={40}
              color={checkForValues(Gender.MEN) ? '#000' : '#c4c4c4'}
              style={{ opacity: checkForValues(Gender.MEN) ? 1 : 0.4 }}
            />
          </TouchableOpacity>
          <Spacer width={15} />
          <TouchableOpacity
            onPress={() => {
              if (disabled) {
                openSnackbar(
                  'The combined length of this tee is less then 1500yd, please move tees in LiveCR'
                )
                return
              }
              if (checkForValues(Gender.WOMEN)) {
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.WOMEN,
                    tee: indexOfTee.toString(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            }}
          >
            <FoundationIcon
              name="female-symbol"
              size={40}
              color={checkForValues(Gender.WOMEN) ? '#000' : '#c4c4c4'}
              style={{ opacity: checkForValues(Gender.WOMEN) ? 1 : 0.4 }}
            />
          </TouchableOpacity>
        </div>
      </FormExGenderBtn>
    </MenuItem>
  )
}

const FormsTab: React.FC = () => {
  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const user = useUser()
  const [tee, settee] = useState<number>(0)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [showSpinner, setShowSpinner] = useState(false)

  if (!evaluation || !user?.id || !view || !currentId) return null

  const invalidCourseLength = useCourseLengthCheck(evaluation)
  if (evaluation.holes) {
    const manualVersion = evaluation?.manualVersion ?? '2024'
    const tees = evaluation.holes[view?.hole].tees

    const formsToRender = getExportFormItems(evaluation, manualVersion).filter(
      (item) => !(evaluation.holes.length <= 9 && item.title === 'Form 1-2')
    )

    const formIsDisabled = (form: ExportFormItem) => {
      const toDisable = ['Form 2', 'Form 3']
      return !!(toDisable.includes(form.title) && invalidCourseLength?.length)
    }

    const hasCourseLengthError =
      invalidCourseLength?.length && invalidCourseLength.length > 0

    return (
      <ScrollView>
        <Spacer height={16} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <TitleText>WHS: {evaluation?.manualVersion}</TitleText>
          {!!hasCourseLengthError && (
            <>
              {invalidCourseLength.some((item) => item.diff === null) ? (
                <CourseLengthWarningContainer>
                  <AlertIconContainer>
                    <FeatherIcon
                      name="alert-circle"
                      size={24}
                      color="#92400e"
                    />
                    <AlertTitle>Non-standard Course Configuration</AlertTitle>
                  </AlertIconContainer>
                  <AlertDescription>
                    This course does not have a standard amount of holes (9/18)
                    or the course length is not able to be calculated.
                  </AlertDescription>
                  <AlertDescription style={{ marginTop: 8, fontWeight: '500' }}>
                    This might result in unwanted behavior in the forms or
                    incorrect calculations. Contact Mapping Industries for more
                    information.
                  </AlertDescription>
                </CourseLengthWarningContainer>
              ) : (
                <CourseLengthErrorContainer>
                  <AlertIconContainer>
                    <FeatherIcon
                      name="alert-triangle"
                      size={24}
                      color="#991b1b"
                    />
                    <AlertTitle style={{ color: '#991b1b' }}>
                      Insufficient Course Length
                    </AlertTitle>
                  </AlertIconContainer>
                  <AlertDescription style={{ color: '#991b1b' }}>
                    Course does not meet the minimum length requirement of{' '}
                    {getMinCourseLength(
                      evaluation.manualVersion ?? '2024',
                      evaluation.holes.length
                    )}{' '}
                    yards for a rating
                  </AlertDescription>
                  <AlertList>
                    {invalidCourseLength.map((item) => (
                      <AlertListItem key={item.tee}>
                        <FeatherIcon
                          name="minus"
                          size={16}
                          color="#991b1b"
                          style={{ marginRight: 8 }}
                        />
                        <AlertListText style={{ color: '#991b1b' }}>
                          {`${item.tee} is ${item.diff} yards short. (Actual length: ${item.actualLength} yards.)`}
                        </AlertListText>
                      </AlertListItem>
                    ))}
                  </AlertList>
                </CourseLengthErrorContainer>
              )}
            </>
          )}
          <TeeContainer>
            <MenuDiv>
              <TitleText>Tee</TitleText>
              <InnerTeeContianer>
                {tees.map((miTee, i) => (
                  <TeeButton
                    key={i}
                    selected={tee === i}
                    onPress={() => settee(i)}
                  >
                    <span style={{ color: theme.colors.white }}>
                      {miTee.teeNumber}
                    </span>
                  </TeeButton>
                ))}
              </InnerTeeContianer>
            </MenuDiv>
            {formsToRender.map((item: ExportFormItem, index: number) => {
              return (
                <FormItem
                  item={item}
                  key={index}
                  tee={tees[tee]}
                  indexOfTee={tee}
                  startDate={startDate}
                  disabled={formIsDisabled(item)}
                />
              )
            })}
          </TeeContainer>
        </View>
        <Spacer height={8} />
      </ScrollView>
    )
  }

  return null
}

export default FormsTab

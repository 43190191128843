import { MITee } from '@app/evaluation-core/types'
import React, { useCallback, useEffect } from 'react'
import { ButtonContainer } from '../../atoms/ContinueButton/styled'
import * as S from './styled'
import styled from 'styled-components'

type TeeColorPickerProps = {
  tees?: MITee[]
  onSave?: (teeColors: string[], teeNames: string[]) => void
}
const Container = styled.div`
  display: flex;
  gap: 8px;
`

const TeeCard = styled.div`
  width: 100px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ColorContainer = styled.div`
  padding: 8px 8px 2px 8px;
  width: 90%;
  text-align: center;
`

const NameContainer = styled.div`
  padding: 2px 8px 8px 8px;
`


const ColorInput = styled.input.attrs({ type: 'color' })`
  width: 100%;
  height: 32px;
`

const NameInput = styled.input.attrs({ type: 'text' })`
  width: 90%;
  padding: 4px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  text-align: center;
`

const TeeColorPicker = ({ tees = [], onSave }: TeeColorPickerProps) => {
  const [teesState, setTeesState] = React.useState<MITee[]>([])

  useEffect(() => {
    setTeesState(
      tees.map(tee => ({
        ...tee,
        color: tee.color?.startsWith('#') ? tee.color : `#${tee.color}`
      }))
    )
  }, [tees])

  const handleColorChange = useCallback((color: string, index: number) => {
    const newTees = [...teesState]
    newTees[index] = {
      ...newTees[index],
      color: color.startsWith('#') ? color : `#${color}`
    }
    setTeesState(newTees)
  }, [teesState])

  const handleNameChange = useCallback((name: string, index: number) => {
    const newTees = [...teesState]
    newTees[index] = {
      ...newTees[index],
      name
    }
    setTeesState(newTees)
  }, [teesState])

  const handleSubmit = () => {
    const teeColors = teesState.reduce((acc: string[], tee) => tee.color ? [...acc, tee.color] : acc, [])
    const teeNames = teesState.reduce((acc: string[], tee) => tee.name ? [...acc, tee.name] : acc, [])
    onSave?.(teeColors, teeNames)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', paddingTop: '8px'}}>
    <Container>
      {teesState.map((tee, index) => (
        <TeeCard key={index}>
          <ColorContainer>
            <ColorInput
              value={tee.color}
              onChange={(e) => handleColorChange(e.target.value, index)}
              aria-label={`${tee.name} color picker`}
            />
          </ColorContainer>
          <NameContainer>
            <NameInput
              value={tee.name}
              onChange={(e) => handleNameChange(e.target.value, index)}
              aria-label={`${tee.name} name editor`}
            />
          </NameContainer>
        </TeeCard>
      ))}
    </Container>
      <ButtonContainer style={{marginLeft: 'auto'}} onClick={handleSubmit}>
        <S.ButtonText>SAVE TEE INFO</S.ButtonText>
      </ButtonContainer>
    </div>
  )
}

export default TeeColorPicker

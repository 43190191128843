export const MIN_COURSE_LENGTH_2020 = {
  9: 1500,
  18: 3000,
}

export const MIN_COURSE_LENGTH_2024 = {
  9: 750,
  18: 1500,
}
export const getMinCourseLength = (manualVersion?: string, holes?: number) => {
  if (!manualVersion || !holes) return undefined

  switch (manualVersion) {
    case '2020':
      return MIN_COURSE_LENGTH_2020[holes]
    case '2024':
      return MIN_COURSE_LENGTH_2024[holes]
    default:
      return undefined
  }
}

import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { Golfer } from '@app/evaluation-core/types'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import { getGolferPlayerFromShortName } from '@app/evaluation-core/actions/evaluationActions'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useFeature } from '@app/features/hooks'

const LATERAL_FACTORS = (
  view,
  _adjustments,
  shots: number
): AdmFactorGroup[] => [
  {
    factors: [
      {
        label: 'B*',
        itemKey: 'lat_bounce',
        format: 'shot',
        showOr: (adjustments, view) =>
          (getAdjustmentValue(
            'shot',
            view,
            'lateralDistance',
            adjustments
          ) as number) >= 15,
        items: [
          {
            value: 1,
            text: `If conditions (such as a cart path or sloping ground) increase the likelihood that a ball will BOUNCE into the obstacle. Do not use if the distance from the centre of the target landing zone is less than 15 yards.`,
            shouldRender: (adjustments, view) =>
              (getAdjustmentValue(
                'shot',
                view,
                'lateralDistance',
                adjustments
              ) as number) >= 15,
          },
          {
            value: -1,
            text: `If conditions (such as a tree, fence or other obstacle) decrease the likelihood that a ball will BOUNCE or fly into the obstacle or the obstacle only comes into play behind the green`,
          },
        ],
      },
      {
        label: 'K*',
        itemKey: 'lat_stroke',
        format: 'shot',
        shouldRender: (adjustments, view) => {
          const shots = getPlayerShots(view.golfer)
          if (view.shot === shots - 1) {
            return false
          }
          const bounce = getAdjustmentValue(
            'shot',
            view,
            'lat_bounce',
            adjustments
          ) as number
          const rating = getAdjustmentValue(
            'shot',
            view,
            'lat_rating',
            adjustments
          ) as number
          return bounce + rating > 1
        },
        items: [
          {
            value: 1,
            text: 'If the area is not treated as a lateral penalty area and a STROKE and distance penalty applies, such as OB or where a ball is likely to be lost in extreme rough.',
          },
        ],
      },
      {
        label: 'K*',
        itemKey: 'lat_stroke_green',
        format: 'hole',
        shouldRender: (adjustments, view) => {
          const shots = getPlayerShots(view.golfer)
          const bounce = getAdjustmentValue(
            'shot',
            view,
            'lat_bounce',
            adjustments
          ) as number
          const rating = getAdjustmentValue(
            'shot',
            view,
            'lat_rating',
            adjustments
          ) as number
          return bounce + rating > 1 && view.shot === shots - 1
        },
        items: [
          {
            value: 1,
            text: 'If the area is not treated as a lateral penalty area and a STROKE and distance penalty applies, such as OB or where a ball is likely to be lost in extreme rough.',
          },
        ],
      },
      {
        label: 'P*',
        itemKey: 'lat_barrierPercentage',
        format: 'shot',
        factorText: `If the obstacle is narrow, only borders part of the landing zone, can be played from some of the time, and/or a significant barrier (tall net, steep slope, etc.) minimizes the chance that a shot would reach the lateral obstacle, consider using an appropriate PERCENTAGE of the table value plus above adjustments to determine the rating value.`,
        items: [
          {
            value: '100%',
          },
          {
            value: '75%',
          },
          {
            value: '50%',
          },
          {
            value: '25%',
          },
        ],
      },
      {
        label: 'Q*',
        itemKey: 'lat_squeeze',
        format: 'shot',
        shouldRender: (adjustments, view) => view.shot + 1 !== shots,
        items: [
          {
            value: 1,
            text: `If obstacle SQUEEZE occurs because the distance between Lateral Obstacles that border both sides of the fairway landing zone is less than 40 yards.`,
          },
          {
            value: 2,
            text: `If obstacle SQUEEZE occurs because the distance between Lateral Obstacles that border both sides of the fairway landing zone is less than 30 yards.`,
          },
        ],
      },
      {
        label: 'S*',
        itemKey: 'lat_fraction',
        format: 'shot',

        factorText:
          'If the green is closely SURROUNDED by crossing and/or lateral obstacles. Green Target rating value must be 5 or greater for the scratch player but may be any value for the bogey player. Refer to the manual for to see the table used to calculate this value.',
        shouldRender: (adjustments, view) => {
          if (view.shot + 1 !== shots) {
            return false
          }
          const percentage = getAdjustmentValue(
            'shot',
            view,
            'lat_barrierPercentage',
            adjustments
          ) as number
          const gtRating = getAdjustmentValue(
            'golfer',
            view,
            'gt_final',
            adjustments
          ) as number

          // In determining a SURROUNDED (S) adjusment, ignore any area where a PERCENTAGE (P) adjustment has been used to reduce the value by more than 50%.
          if (percentage < 50) {
            return false
          }
          //  Green Target rating value must be 5 or greater for the scratch player but may be any value for the bogey player
          return getGolferPlayerFromShortName(view.golfer) === Golfer.SCRATCH
            ? gtRating >= 5
            : true
        },
        items: [
          {
            value: 1,
            disabled: true,
          },
          {
            value: 2,
            disabled: true,
          },
        ],
      },
      {
        label: '2',
        itemKey: 'lat_two',
        format: 'shot',
        showOr: true,
        factorText: `If a lateral obstacle comes into play on two (2) or more shots – each rating must be 5 or greater (table value plus adjustments). Adjustment is made to the highest of the separate shot evaluations. Add all values of 5 or greater; if they total 11 or less, +1; if they total 12 or more, +2`,
        items: [
          {
            value: 1,
            disabled: true,
          },
          {
            value: 2,
            disabled: true,
          },
        ],
      },
    ],
  },
]

export const LateralObstacles = () => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const adjustments = useAdjustment()
  const shots = getPlayerShots()

  if (!view || !evaluation) return null

  return (
    <Adjustment
      useLz={true}
      factors={LATERAL_FACTORS(view, adjustments, shots)}
      parameters={[
        {
          label: 'Distance of obstacle from center of target',
          adjustmentKey: 'lateralDistance',
          format: 'shot',
          stepperValues: [1, 5, 10, 50],
          stepDefault: 10,
          applyToAllApproachShots: true,
        },
        {
          label: 'Shot length to target',
          adjustmentKey: 'shotLength',
          format: 'shot',
          stepperValues: [1, 5, 10, 50],
          stepDefault: 10,
        },
        {
          label: 'Proximity of obstacle to the edge of the green',
          adjustmentKey: 'waterHazardGreenEdge',
          format: 'hole',
          notExpandible: true,
          unit: 'yards',
        },
        {
          label: 'Fraction of green surrounded',
          adjustmentKey: 'waterHazardGreenEdgeFractionPercentage',
          unit: '%',
          format: 'hole',
          notExpandible: true,
        },
        {
          type: 'table-value',
          label: 'Lateral Obstacle Rating',
          format: 'shot',
          inputDisabled: true,
          adjustmentKey: 'lat_rating',
        },
      ]}
    />
  )
}

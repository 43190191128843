import React from 'react'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { GeneratedForms } from '../../../types/Templates'
import firebase from 'firebase'
import DateTimeView from '../../atoms/DateTimeView'
import Spacer from '../../atoms/Spacer'

type CompositeFinalRatingItemProps = {
  form: GeneratedForms
  showArchiveFormsModal: (formId: string) => void
}

export const downloadFolder = (ref: string) => {
  const doc = firebase.storage().ref(ref)
  doc.getDownloadURL().then((url) => {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = document.title || ''
    anchor.click()
  })
}

const CompositeFinalRatingItem: React.FC<CompositeFinalRatingItemProps> = ({
  form,
  showArchiveFormsModal,
}) => {
  return (
    <tr>
      <td colSpan={6}>
        <div>
          <h4 style={{ marginBlockEnd: '0.5em' }}>Composite Forms Details</h4>
          <p style={{ marginBlock: '0.5em' }}>
            Generated:{' '}
            <DateTimeView
              date={form.createdAt ? new Date(form.createdAt) : new Date()}
            />
          </p>
          <p>Title: {form.title}</p>
          <Spacer height={4} />

          <p>Rating Name: {form?.name}</p>
          <p>
            Rating Date:{' '}
            {form.ratingDate && (
              <DateTimeView
                date={form.ratingDate ? new Date(form.ratingDate) : new Date()}
                showTime={false}
              />
            )}
          </p>
          <p>Club Name: {form?.clubName}</p>
          <p>Course Name: {form?.courseName}</p>
          <div
            style={{
              display: 'flex',
              width: 300,
              marginBlockEnd: '2em',
              flexDirection: 'row',
              gap: 8,
            }}
          >
            <RoundedButton
              title="Download Forms"
              onPress={() => downloadFolder(form.zipPath)}
            />
            <RoundedButton
              title="Delete"
              onPress={() => showArchiveFormsModal(form.id)}
              style={{ backgroundColor: '#d35' }}
            />
          </div>
        </div>
      </td>
    </tr>
  )
}
export default CompositeFinalRatingItem

import { useState, useCallback, useMemo } from 'react'
import { SearchClubData } from './types'
import useUser from '@common/hooks/useUser'

interface UseEvaluationFiltersProps {
  groupedData: SearchClubData[]
}

interface UseEvaluationFiltersReturn {
  searchTerm: string
  setSearchTerm: (term: string) => void
  selectedDistricts: string[]
  handleDistrictSelect: (district: string) => void
  selectedRegions: string[]
  handleRegionSelect: (region: string) => void
  selectedEvalStatuses: number[]
  handleEvalStatusSelect: (status: number) => void
  selectedFinalizedYears: string[]
  handleFinalizedYearSelect: (year: string) => void
  selectedEvaluationYears: string[]
  handleEvaluationYearSelect: (year: string) => void
  selectedManualVersions: string[]
  handleManualVersionSelect: (version: string) => void
  includeDemoRatings: boolean
  handleToggleDemoRatings: () => void
  clearFilters: () => void
  filteredGroupedData: SearchClubData[]
}

export const useEvaluationFilters = ({
  groupedData,
}: UseEvaluationFiltersProps): UseEvaluationFiltersReturn => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedDistricts, setSelectedDistricts] = useState<string[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [selectedEvalStatuses, setSelectedEvalStatuses] = useState<number[]>([])
  const [selectedFinalizedYears, setSelectedFinalizedYears] = useState<
    string[]
  >([])
  const [selectedEvaluationYears, setSelectedEvaluationYears] = useState<
    string[]
  >([])
  const [selectedManualVersions, setSelectedManualVersions] = useState<
    string[]
  >([])
  const [includeDemoRatings, setIncludeDemoRatings] = useState<boolean>(false)

  const handleToggleDemoRatings = useCallback(() => {
    setIncludeDemoRatings((prev) => !prev)
  }, [])

  const handleDistrictSelect = useCallback((district: string) => {
    setSelectedDistricts((prev) =>
      prev.includes(district)
        ? prev.filter((d) => d !== district)
        : [...prev, district]
    )
  }, [])

  const handleRegionSelect = useCallback((region: string) => {
    setSelectedRegions((prev) =>
      prev.includes(region)
        ? prev.filter((r) => r !== region)
        : [...prev, region]
    )
  }, [])

  const handleEvalStatusSelect = useCallback((status: number) => {
    setSelectedEvalStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    )
  }, [])

  const handleFinalizedYearSelect = useCallback((year: string) => {
    setSelectedFinalizedYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    )
  }, [])

  const handleEvaluationYearSelect = useCallback((year: string) => {
    setSelectedEvaluationYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    )
  }, [])

  const handleManualVersionSelect = useCallback((version: string) => {
    setSelectedManualVersions((prev) =>
      prev.includes(version)
        ? prev.filter((v) => v !== version)
        : [...prev, version]
    )
  }, [])

  const clearFilters = useCallback(() => {
    setSelectedDistricts([])
    setSelectedRegions([])
    setSelectedEvalStatuses([])
    setSelectedFinalizedYears([])
    setSelectedEvaluationYears([])
    setSelectedManualVersions([])
  }, [])

  const filteredGroupedData = useMemo(() => {
    let filtered = [...groupedData]

    if (searchTerm) {
      const normalizedSearchTerm = searchTerm.toLowerCase()
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          const matchesClubName = club.clubName
            .toLowerCase()
            .includes(normalizedSearchTerm)
          const matchesCourseName = evaluation.courseName
            ?.toLowerCase()
            .includes(normalizedSearchTerm)
          const matchesSaveName = evaluation.saveName
            ?.toLowerCase()
            .includes(normalizedSearchTerm)
          const matchesId = evaluation.id
            ?.toLowerCase()
            .includes(normalizedSearchTerm)

          return (
            matchesClubName || matchesCourseName || matchesSaveName || matchesId
          )
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    if (selectedDistricts.length > 0) {
      filtered = filtered.filter((club) => {
        return (
          club.districtName && selectedDistricts.includes(club.districtName)
        )
      })
    }

    if (selectedRegions.length > 0) {
      filtered = filtered.filter((club) => {
        return (
          club.districtRegionName &&
          selectedRegions.includes(club.districtRegionName)
        )
      })
    }

    if (selectedEvalStatuses.length > 0) {
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          return selectedEvalStatuses.includes(evaluation.status)
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    // Filter by finalized year
    if (selectedFinalizedYears.length > 0) {
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          if (!evaluation.finalizedAt) {
            return selectedFinalizedYears.includes('Not Finalized')
          }

          const finalizedYear = new Date(evaluation.finalizedAt)
            .getFullYear()
            .toString()
          return selectedFinalizedYears.includes(finalizedYear)
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    if (selectedEvaluationYears.length > 0) {
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        // Filter evaluations by evaluation year
        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          if (!evaluation.evaluationDate) {
            return selectedEvaluationYears.includes('Unknown')
          }

          const evaluationYear = new Date(evaluation.evaluationDate)
            .getFullYear()
            .toString()
          return selectedEvaluationYears.includes(evaluationYear)
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    if (selectedManualVersions.length > 0) {
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          if (!evaluation.manualVersion) {
            return selectedManualVersions.includes('Unknown')
          }

          const manualVersion = evaluation.manualVersion.toString()
          return selectedManualVersions.includes(manualVersion)
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    if (!includeDemoRatings) {
      filtered = filtered.map((club) => {
        const filteredClub = { ...club }

        const allEvaluations = club.evaluations || []

        const filteredEvaluations = allEvaluations.filter((evaluation) => {
          return !evaluation.isDemoEvaluation
        })

        filteredClub.evaluations = filteredEvaluations

        return filteredClub
      })
    }

    // Remove clubs with no evaluations
    filtered = filtered.filter((club) => {
      const hasEvaluations = club.evaluations.length > 0
      return hasEvaluations
    })

    return filtered
  }, [
    groupedData,
    searchTerm,
    selectedDistricts,
    selectedRegions,
    selectedEvalStatuses,
    selectedFinalizedYears,
    selectedEvaluationYears,
    selectedManualVersions,
    includeDemoRatings,
  ])

  return {
    searchTerm,
    setSearchTerm,
    selectedDistricts,
    handleDistrictSelect,
    selectedRegions,
    handleRegionSelect,
    selectedEvalStatuses,
    handleEvalStatusSelect,
    selectedFinalizedYears,
    handleFinalizedYearSelect,
    selectedEvaluationYears,
    handleEvaluationYearSelect,
    selectedManualVersions,
    handleManualVersionSelect,
    includeDemoRatings,
    handleToggleDemoRatings,
    clearFilters,
    filteredGroupedData,
  }
}

import { ColorPickerOverlay } from '@app/evaluation/components/ColorPickerOverlay'
import { State } from '@app/state/types'
import React, { useEffect, useRef } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import Modal from 'react-native-modalbox'
import { useDispatch, useSelector } from 'react-redux'
import EditProfileOverlay from '../../profile/components/EditProfileOverlay/EditProfileOverlay'
import AddTeeOverlay from '../../slope/components/AddTeeOverlay'
import EditCourseSettingsModal from '@app/evaluation/components/EditCourseSettingsModal'
import MixedEvaluationModal from '@app/components/organisms/MixedEvaluationModal'
import ConfirmCourseSettingsModal from '@app/evaluation/components/ConfirmDefaultCourseSettingsModal'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import MissingRatingNameModal from '@app/components/organisms/MissingRatingNameModal'
import SearchInvitableRatersModal from '@app/evaluation/components/SearchInvitableRaterModal'

interface ModalWrapperProps {
  children?: React.ReactNode
}

export const ModalWrapper = ({ children }: ModalWrapperProps) => {
  const modalBox = useRef<Modal>(null)
  const dispatch = useDispatch<any>()
  const { modal } = useSelector((state: State) => ({ modal: state.modal }))

  function _renderModal(onClose?: () => void) {
    switch (modal?.mode) {
      case 'colorOverlay':
        return (
          <ColorPickerOverlay
            defaultColor={modal?.defaultColor}
            handleChange={modal?.handleChange}
            userId={modal?.userId}
          />
        )
      case 'addTee':
        return <AddTeeOverlay {...modal?.props} />
      case 'editCourseSettings':
        return <EditCourseSettingsModal {...modal?.props} onClose={onClose} />
      case 'searchInvitableRaters':
        return (
          <SearchInvitableRatersModal {...modal?.props} onClose={onClose} />
        )
      case 'editProfile':
        return <EditProfileOverlay {...modal?.props} />
      case 'createMixedEvaluation':
        return <MixedEvaluationModal {...modal?.props} />
      case 'setMissingRatingName':
        return (
          <MissingRatingNameModal
            {...modal?.props}
            handleMissingNameFinalize={(name: string) => {
              if (modal?.handleMissingNameFinalize) {
                modal.handleMissingNameFinalize(name)
              }
              if (onClose) {
                onClose()
              }
            }}
            onClose={onClose}
          />
        )
      case 'confirmDefaultCourseSettings':
        return (
          <ConfirmCourseSettingsModal
            {...modal?.props}
            handleChange={() => {
              if (modal?.handleChange) {
                modal.handleChange()
              }
              if (onClose) {
                onClose()
              }
            }}
            onClose={onClose}
          />
        )
      default:
        return null
    }
  }

  const transparentBackground =
    modal?.mode === 'colorOverlay' ||
    modal?.mode === 'addTee' ||
    modal?.mode === 'editProfile' ||
    modal?.mode === 'editCourseSettings' ||
    modal?.mode === 'setMissingRatingName' ||
    modal?.mode === 'confirmDefaultCourseSettings'

  useEffect(() => {
    if (modal) {
      return modalBox.current?.open()
    }

    return modalBox.current?.close()
  }, [modal])

  if (!modal) return null

  const modalStyle = transparentBackground
    ? styles.colorPickerModal
    : styles.modal

  const style = {
    zIndex: 1000,
    flex: 1,
    padding: 20,
    paddingTop: 18,
    overflow: 'visible',
  }

  const transparentBackgroundStyles = {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const combinedStyles =
    style && transparentBackground
      ? { ...style, ...transparentBackgroundStyles }
      : style

  const onClose = () => dispatch({ type: 'SET_MODAL', params: null })

  return (
    <Modal
      isOpen={false}
      style={modalStyle}
      position={'center'}
      isDisabled={false}
      ref={modalBox}
      backdropOpacity={0.3}
      onClosed={() => dispatch({ type: 'SET_MODAL', params: null })}
      // swipeToClose={modal?.mode !== 'colorOverlay'}
      backdropPressToClose={false}
    >
      <div style={combinedStyles}>{_renderModal(onClose)}</div>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'white',
    borderRadius: 20,
    minHeight: '70%',
    maxWidth: 1040,
    maxHeight: '90%',
  },
  colorPickerModal: {
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
  },

  btn: {
    margin: 10,
    backgroundColor: '#3B5998',
    color: 'white',
    padding: 10,
  },

  btnModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 50,
    height: 50,
    backgroundColor: 'transparent',
  },

  text: {
    color: 'black',
    fontSize: 22,
  },
})

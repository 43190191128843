import * as React from 'react'

import { Text, View } from 'react-native'
import {
  clearEvaluationData,
  clearEvaluationState,
  clearMixedEvaluationData,
  submitEvaluation,
} from '@app/evaluation-core/evaluation'

import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import Spacer from '@app/components/atoms/Spacer'
import { TeamRole } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useFirebaseConnect } from 'react-redux-firebase'
import { useSnackbar } from '@app/snackbar'
import useEvaluatable from '../useEvaluatable'
import TeeSelector from './components/TeeSelector'
import useUser from '@common/hooks/useUser'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { useDispatch } from 'react-redux'
import fb from '@app/firebase'
import { useCourseLengthCheck } from '@common/hooks/useCourseLengthCheck'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import {
  AlertDescription,
  AlertIconContainer,
  AlertList,
  AlertListItem,
  AlertListText,
  AlertTitle,
  CourseLengthErrorContainer,
} from '../../screen/FormsTab/styled'
import { getMinCourseLength } from '@common/utils/getMinCourseLength'

const Finalize: React.FC = () => {
  useEvaluatable()
  useFirebaseConnect(['courses'])
  const evalUser = useEvaluationUser()
  const dispatch = useDispatch()
  const user = useUser()
  const { evaluation } = useEvaluation()
  const [openSnackbar] = useSnackbar()

  const invalidCourseLength = useCourseLengthCheck(evaluation)
  const hasInvalidCourseLength =
    invalidCourseLength?.length && invalidCourseLength.length > 0

  const hasLeaderRights =
    evalUser.role === TeamRole.LEADER ||
    evalUser.role === TeamRole.SYSTEM_ADMIN ||
    user.isAdmin

  const allHolesLocked = evaluation?.holes?.every((hole) => hole.locked)

  const handleMissingNameFinalize = async (nameInput: string) => {
    try {
      console.log('nameInput', nameInput)
      await fb.ref(`evaluations/${evaluation.id}/saveName`).set(nameInput)
      await submitEvaluation(user, nameInput)
    } catch (error) {
      console.log(error)
    }
  }

  const submitEval = async () => {
    if (!allHolesLocked) {
      openSnackbar('All holes must be locked to finalize an rating.')
      return
    }
    if (!hasLeaderRights) {
      openSnackbar('Only a team leader can finalize an rating.')
      return
    }
    if (evaluation.status === EvalStatus.FINALIZED) {
      openSnackbar('Rating has already been finalized.')
      return
    }

    await submitEvaluation(user, evaluation?.saveName || '')
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
      }}
    >
      <TeeSelector />
      <Text style={{ fontSize: 24 }}>Finalize rating</Text>
      <Spacer height={20} />
      {!!hasInvalidCourseLength && (
        <CourseLengthErrorContainer>
          <AlertIconContainer>
            <FeatherIcon name="alert-triangle" size={24} color="#991b1b" />
            <AlertTitle style={{ color: '#991b1b' }}>
              Cannot Finalize - Insufficient Course Length
            </AlertTitle>
          </AlertIconContainer>
          <AlertDescription style={{ color: '#991b1b' }}>
            Course does not meet the minimum length requirement of{' '}
            {getMinCourseLength(
              evaluation.manualVersion ?? '2024',
              evaluation.holes.length
            )}{' '}
            yards for a rating. Please adjust the course length before
            finalizing.
          </AlertDescription>
          <AlertList>
            {invalidCourseLength.map((item) => (
              <AlertListItem key={item.tee}>
                <FeatherIcon
                  name="minus"
                  size={16}
                  color="#991b1b"
                  style={{ marginRight: 8 }}
                />
                <AlertListText style={{ color: '#991b1b' }}>
                  {`${item.tee} is ${item.diff} yards short. (Actual length: ${item.actualLength} yards.)`}
                </AlertListText>
              </AlertListItem>
            ))}
          </AlertList>
        </CourseLengthErrorContainer>
      )}
      <Spacer height={20} />
      {!evaluation.isMixed && !hasInvalidCourseLength && (
        <RoundedButton
          style={{ backgroundColor: theme.colors.secondary2 }}
          onPress={submitEval}
          title="Submit"
          testId="btn-save-evaluation"
        />
      )}
    </View>
  )
}

export default Finalize

import { Eval } from '@app/evaluation-core/types'
import React from 'react'
import { useState } from 'react'
import * as S from '../styled'
import {
  FaArrowRight,
  FaCheckCircle,
  FaExclamation,
  FaExclamationCircle,
  FaFlag,
  FaThumbsUp,
} from 'react-icons/fa'
import { getStatusText } from '../helpers'
import { ActivityIndicator } from 'react-native'

interface EvaluationItemProps {
  evaluation: Eval & { courseName?: string }
  isAdminUser: boolean
  onEvaluationClick: (evaluationId: string) => void
  onMarkAsDemo: (evaluationId: string, currentlyDemo: boolean) => void
  onMarkAsActive: (evaluationId: string, clubId: string) => void
}

const SearchEvaluationItem = React.memo<EvaluationItemProps>(
  ({
    evaluation,
    isAdminUser,
    onEvaluationClick,
    onMarkAsDemo,
    onMarkAsActive,
  }) => {
    const [isNavigateLoading, setIsNavigateLoading] = useState(false)
    const [isMarkAsDemoLoading, setIsMarkAsDemoLoading] = useState(false)
    const [isMarkAsActiveLoading, setIsMarkAsActiveLoading] = useState(false)

    const handleGoToEvaluation = (evaluation: Eval) => {
      if (!evaluation.id) {
        console.error('Evaluation ID is null')
        return
      }
      setIsNavigateLoading(true)
      onEvaluationClick(evaluation.id)
      setTimeout(() => {
        setIsNavigateLoading(false)
      }, 2000)
    }

    const handleMarkAsDemo = (evaluation: Eval) => {
      if (!evaluation.id) {
        console.error('Evaluation ID is null')
        return
      }
      setIsMarkAsDemoLoading(true)
      onMarkAsDemo(evaluation.id, !!evaluation.isDemoEvaluation)
      setTimeout(() => {
        setIsMarkAsDemoLoading(false)
      }, 2000)
    }

    const handleMarkAsActive = (evaluation: Eval) => {
      if (!evaluation.id) {
        console.error('Evaluation ID is null')
        return
      }
      setIsMarkAsActiveLoading(true)
      onMarkAsActive(evaluation.id, evaluation.clubId.toString())
      setTimeout(() => {
        setIsMarkAsActiveLoading(false)
      }, 2000)
    }

    return (
      <S.EvaluationItem key={evaluation.id} tabIndex={0}>
        <S.EvaluationMainContent>
          {evaluation.isActiveFinalized && <FaCheckCircle color={'#22c55e'} />}
          <S.EvaluationTitle>
            {evaluation.saveName?.slice(0, 35) || '-'}
          </S.EvaluationTitle>
          <S.EvaluationMetaRow>
            <S.StatusBadge status={evaluation.status?.toString()}>
              {getStatusText(evaluation.status)}
            </S.StatusBadge>
            {evaluation.courseName && (
              <S.CourseBadge>{evaluation.courseName}</S.CourseBadge>
            )}
            {evaluation.isDemoEvaluation && (
              <S.EvaluationMeta>
                <strong>Demo:</strong>
                <FaExclamationCircle color={'orange'} />
              </S.EvaluationMeta>
            )}
            {evaluation.evaluationDate && (
              <S.EvaluationMeta>
                <strong>Rated:</strong>
                {evaluation.evaluationDate
                  ? new Date(evaluation.evaluationDate)
                      .toISOString()
                      .split('T')[0]
                  : '-'}
              </S.EvaluationMeta>
            )}
            {evaluation.finalizedAt && (
              <S.EvaluationMeta>
                <strong>Finalized:</strong>
                {evaluation.finalizedAt
                  ? new Date(evaluation.finalizedAt).toISOString().split('T')[0]
                  : '-'}
              </S.EvaluationMeta>
            )}
            <S.ManualVersionBadge>
              Manual {evaluation.manualVersion ?? 'Unknown'}
            </S.ManualVersionBadge>
            <S.EvaluationMeta>
              <strong>ID:</strong> {evaluation.id}
            </S.EvaluationMeta>
          </S.EvaluationMetaRow>
          <S.ActionButtons>
            <S.IconButton
              title="Go to Rating"
              onClick={() => handleGoToEvaluation(evaluation)}
              style={{
                backgroundColor: '#4190e0',
              }}
            >
              {isNavigateLoading ? (
                <ActivityIndicator size={14} color="white" />
              ) : (
                <FaArrowRight />
              )}
            </S.IconButton>
            {isAdminUser && (
              <S.IconButton
                title={
                  evaluation.isDemoEvaluation
                    ? 'Unmark as DEMO'
                    : 'Mark as DEMO'
                }
                onClick={() => handleMarkAsDemo(evaluation)}
                style={{
                  backgroundColor: evaluation.isDemoEvaluation
                    ? 'white'
                    : '#f97316',
                }}
              >
                {isMarkAsDemoLoading ? (
                  <ActivityIndicator size={14} color="white" />
                ) : (
                  <FaExclamation
                    color={evaluation.isDemoEvaluation ? '#f97316' : 'white'}
                  />
                )}
              </S.IconButton>
            )}
            {evaluation.finalizedAt && !evaluation.isActiveFinalized && (
              <S.IconButton
                title="Mark as Active"
                onClick={() => handleMarkAsActive(evaluation)}
                style={{ backgroundColor: '#22c55e' }}
              >
                {isMarkAsActiveLoading ? (
                  <ActivityIndicator size={14} color="white" />
                ) : (
                  <FaCheckCircle />
                )}
              </S.IconButton>
            )}
          </S.ActionButtons>
        </S.EvaluationMainContent>
      </S.EvaluationItem>
    )
  }
)

export default SearchEvaluationItem

import styled from 'styled-components'
import { ExpandIconProps } from './types'
import theme from '@app/ui/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  padding: 32px;
  gap: 24px;
  background-color: #f8fafc;
  overflow-y: auto;
`

export const SearchSection = styled.div`
  background-color: #f8fafc;
  padding: 16px 0;
  border-bottom: 1px solid #e2e8f0;
`

export const SearchInput = styled.input`
  width: 95%;
  max-width: 900px;
  padding: 16px 24px;
  font-size: 16px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: none;
    border-color: #4190e0;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

export const FilterHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px;
`

export const FilterSectionTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`

export const FilterColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SingleColumnFilterSection = styled(FilterSection)`
  grid-column: 1 / -1;
`

export const FilterPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const FilterPill = styled.button<{ selected: boolean }>`
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid ${({ selected }) => (selected ? '#4190e0' : '#e2e8f0')};
  background-color: ${({ selected }) => (selected ? '#4190e0' : 'white')};
  color: ${({ selected }) => (selected ? 'white' : '#64748b')};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? 'rgb(37, 84, 130)' : '#f1f5f9'};
    border-color: ${({ selected }) =>
      selected ? 'rgb(37, 84, 130)' : '#cbd5e1'};
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const HierarchicalList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px;
`

export const StyledClubItem = styled.div`
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`

export const ClubHeader = styled.div`
  padding: 20px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f1f5f9;
  }
`

export const ClubHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`

export const ExpandIcon = styled.span<ExpandIconProps>`
  font-size: 12px;
  color: #64748b;
  transition: transform 0.2s ease;
  transform: rotate(${({ expanded }) => (expanded ? '0deg' : '-90deg')});
`

export const ClubName = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #1e293b;
`

export const CourseCount = styled.span`
  font-size: 14px;
  color: #64748b;
  background-color: #f1f5f9;
  padding: 4px 8px;
  border-radius: 6px;
`

export const ClubHeaderInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
`

export const ClubInfoBadge = styled.span`
  font-size: 13px;
  color: #64748b;
  background-color: #f1f5f9;
  padding: 4px 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  strong {
    color: #475569;
  }
`

export const CourseList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`

export const StyledCourseItem = styled.div`
  background-color: #f8fafc;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f1f5f9;
  }
`

export const CourseHeader = styled.div`
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
`

export const CourseName = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #334155;
  flex: 1;
`

export const EvaluationCount = styled.span`
  font-size: 13px;
  color: #64748b;
  background-color: white;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
`

export const EvaluationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
`

export const EvaluationItem = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;

  &:hover {
    border-color: #cbd5e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    outline: none;
    border-color: #4190e0;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
`

export const EvaluationMainContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`

export const EvaluationTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #1e293b;
`

export const EvaluationMetaRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
`

export const StatusBadge = styled.span<{ status: string }>`
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  background-color: ${({ status }) => {
    switch (status) {
      case '3': // Finalized
        return '#dcfce7' // Green background
      case '2': // In Progress
        return '#fef9c3' // Yellow background
      case '1': // Preparing
      case '0': // Not Started
        return '#f1f5f9' // Grey background
      default:
        return '#f1f5f9' // Grey background for unknown status
    }
  }};
  color: ${({ status }) => {
    switch (status) {
      case '3': // Finalized
        return '#166534' // Green text
      case '2': // In Progress
        return '#854d0e' // Yellow/amber text
      case '1': // Preparing
      case '0': // Not Started
        return '#475569' // Grey/black text
      default:
        return '#475569' // Grey/black text for unknown status
    }
  }};
  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case '3': // Finalized
          return '#86efac' // Green border
        case '2': // In Progress
          return '#fde68a' // Yellow border
        case '1': // Preparing
        case '0': // Not Started
          return '#cbd5e1' // Grey border
        default:
          return '#cbd5e1' // Grey border for unknown status
      }
    }};
  display: inline-flex;
  align-items: center;
`

export const ManualVersionBadge = styled.span`
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 13px;
  color: #475569;
  background-color: #f1f5f9;
  font-weight: 500;
`

export const EvaluationMeta = styled.span`
  font-size: 13px;
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 4px;

  strong {
    color: #475569;
    font-weight: 500;
  }
`

export const EvaluationDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: #64748b;
  line-height: 1.5;
`

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-size: 18px;
  color: #64748b;
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-size: 18px;
  color: #ef4444;
  background-color: #fee2e2;
  border-radius: 8px;
`

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-size: 18px;
  color: #64748b;
  background-color: #f8fafc;
  border-radius: 8px;
  border: 1px dashed #cbd5e1;
`

export const ExportButton = styled.button<{ disabled?: boolean }>`
  padding: 8px 16px;
  background-color: ${({ disabled }) => (disabled ? '#f1f5f9' : '#4190e0')};
  color: ${({ disabled }) => (disabled ? '#94a3b8' : 'white')};
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#f1f5f9' : '#2563eb')};
  }
`

export const ClearFiltersButton = styled.button`
  padding: 8px 16px;
  background-color: #f1f5f9;
  color: #64748b;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #e2e8f0;
  }
`

export const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`

export const Button = styled.button`
  padding: 8px 12px;
  background-color: #4190e0;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgb(37, 84, 130);
  }

  &:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
  }
`

export const IconButton = styled(Button)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const CourseBadge = styled.span`
  font-size: 13px;
  color: #3b82f6;
  background-color: #eff6ff;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #bfdbfe;
  font-weight: 500;
`

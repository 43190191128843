import React from 'react'
import * as S from '../styled'
import { SearchClubData } from '../types'
import { Eval } from '@app/evaluation-core/types'
import SearchEvaluationItem from './SearchEvaluation'

interface ClubItemProps {
  club: SearchClubData
  isExpanded: boolean
  onToggle: (clubName: string) => void
  onEvaluationClick: (evaluationId: string) => void
  onMarkAsDemo: (evaluationId: string, currentlyDemo: boolean) => void
  onMarkAsActive: (evaluationId: string, clubId: string) => void
  isAdminUser: boolean
}

const SearchClubItem = React.memo<ClubItemProps>(
  ({
    club,
    isExpanded,
    onToggle,
    onEvaluationClick,
    onMarkAsDemo,
    onMarkAsActive,
    isAdminUser,
  }) => {
    const allEvaluations = club.evaluations

    return (
      <S.StyledClubItem>
        <S.ClubHeader
          onClick={() => onToggle(club.clubName)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              onToggle(club.clubName)
            }
          }}
        >
          <S.ClubHeaderMain>
            <S.ExpandIcon expanded={isExpanded}>
              {isExpanded ? '▼' : '►'}
            </S.ExpandIcon>
            <S.ClubName>{club.clubName}</S.ClubName>
            <S.CourseCount>
              {allEvaluations.length} evaluation
              {allEvaluations.length !== 1 ? 's' : ''}
            </S.CourseCount>
          </S.ClubHeaderMain>
          {(club.districtCountry ||
            club.districtName ||
            club.districtRegionName ||
            club.branchStatus) && (
            <S.ClubHeaderInfo>
              {club.districtCountry && (
                <S.ClubInfoBadge>
                  <strong>Country:</strong> {club.districtCountry}
                </S.ClubInfoBadge>
              )}
              {club.districtName && (
                <S.ClubInfoBadge>
                  <strong>District:</strong> {club.districtName}
                </S.ClubInfoBadge>
              )}
              {club.districtRegionName && (
                <S.ClubInfoBadge>
                  <strong>Region:</strong> {club.districtRegionName}
                </S.ClubInfoBadge>
              )}
            </S.ClubHeaderInfo>
          )}
        </S.ClubHeader>

        {isExpanded && (
          <S.EvaluationList>
            {allEvaluations.map((evaluation) => (
              <SearchEvaluationItem
                key={evaluation.id}
                evaluation={evaluation}
                onEvaluationClick={onEvaluationClick}
                onMarkAsDemo={onMarkAsDemo}
                onMarkAsActive={onMarkAsActive}
                isAdminUser={isAdminUser}
              />
            ))}
          </S.EvaluationList>
        )}
      </S.StyledClubItem>
    )
  }
)

export default SearchClubItem

import React, { useState } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps, View } from 'react-native'
import * as S from './styled'
import CourseScorecard from '../CourseScorecard/CourseScorecard'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { RatingTemplate } from '../../../types/Templates'
import { useAxios } from '@common/hooks/useAxios'
import { useSnackbar } from '@app/snackbar'
import formatISODuration from 'date-fns/esm/fp/formatISODuration'

registerLocale('sv', sv)

type Props = {
  isVisible: boolean
  disabled?: boolean
  courseId?: number
  branchId?: string
  projectCourse?: any
  holeIds?: string[]
  closeFn?: any
  isComposite?: boolean
  template?: RatingTemplate
}

const CourseScorecardModal: React.FC<Props> = ({
  isVisible = false,
  disabled = false,
  courseId,
  branchId,
  projectCourse = null,
  holeIds,
  closeFn,
  isComposite = false,
  template,
}) => {
  const [openSnackbar] = useSnackbar()
  const [isDowlnoading, setIsDownloading] = useState(false)
  const downloadScorecard = async () => {
    try {
      setIsDownloading(true)
      const parsedHoleIds =
        holeIds ??
        (projectCourse?.holes.map((hole) => hole.physicalID) as
          | string[]
          | undefined)

      if (!parsedHoleIds || !branchId) {
        return
      }

      const body = JSON.stringify({
        title: (projectCourse?.name as string | undefined) ?? '',
        branchId: +branchId,
        holeIds: parsedHoleIds,
      })

      const resp = await fetch(
        'https://forge.mappingindustries.com/pdf/liverate-scorecard',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        }
      )

      if (!resp.ok) {
        openSnackbar('Failed to download scorecard')
        return
      }

      resp.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'scorecard.pdf')
        link.click()
        setIsDownloading(false)
      })
    } catch (error) {
      openSnackbar('Failed to download scorecard')
      setIsDownloading(false)
    }
  }

  return (
    <S.ModalContainer visible={isVisible}>
      <S.ModalContentContainer>
        <S.ModalContent>
          <CourseScorecard
            holeIds={holeIds}
            courseId={courseId}
            branchId={branchId}
            projectCourse={projectCourse}
            isComposite={isComposite}
            template={template}
          />
          <RoundedButton
            onPress={downloadScorecard}
            disabled={isDowlnoading}
            title={isDowlnoading ? 'Downloading...' : 'Download Scorecard'}
            testId="btn-print"
            style={{
              backgroundColor: theme.colors.secondary2,
              width: '90%',
              height: '34px',
              marginTop: '24px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <RoundedButton
            onPress={closeFn}
            title={'Close'}
            testId="btn-start-course"
            style={{
              width: '90%',
              height: '34px',
              marginTop: '6px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </S.ModalContent>
      </S.ModalContentContainer>
    </S.ModalContainer>
  )
}

export default CourseScorecardModal

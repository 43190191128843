import React, { useState, useEffect } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps } from 'react-native'
import * as S from './styled'
import { useAxios } from '@common/hooks/useAxios'
import {
  ProjectCourse,
  ProjectHole,
  ProjectTee,
  ProjectTeeHole,
} from '../../../types/Project'
import { shadeColor } from '@app/evaluation-core/evaluation'
import { convertToUnits } from './CourseScorecard'

interface ScorecardTotalRowProps {
  course: Partial<ProjectCourse>
  editMode: false
  hole: ProjectHole & { originalHoleNumber?: number }
  projectCourse: Partial<ProjectCourse> | undefined
  sumType: 'in' | 'out' | 'tot'
  startHole: number
  endHole: number
  units: 'metric' | 'imperial'
}

export default function ScorecardTotalRow({
  course,
  editMode,
  hole,
  projectCourse,
  sumType,
  startHole,
  endHole,
  units,
}: ScorecardTotalRowProps) {
  return (
    <tr key={`hole-${startHole}-${endHole}`}>
      <S.TitleTableCell>{sumType.toLocaleUpperCase()}</S.TitleTableCell>
      <S.TitleTableCell>
        {}
        {editMode ? (
          <input
            className="w-auto text-center max-w-10"
            value={Number(
              course?.holes
                ?.filter(
                  (x) =>
                    Number(x.courseHoleNumber) >= startHole &&
                    Number(x.courseHoleNumber) <= endHole
                )
                .reduce((sum, x) => (sum += Number(x.par)), 0)
            )}
          />
        ) : (
          <>
            {Number(
              course?.holes
                ?.filter(
                  (x) =>
                    Number(x.courseHoleNumber) >= startHole &&
                    Number(x.courseHoleNumber) <= endHole
                )
                .reduce((sum, x) => (sum += Number(x.par)), 0)
            )}
          </>
        )}
      </S.TitleTableCell>
      <S.TitleTableCell>-</S.TitleTableCell>
      {hole?.teesOnHole.map((tee: ProjectTeeHole) => {
        const slopeTotal = (projectCourse?.holes ?? course?.holes)
          ?.filter(
            (x) =>
              Number(x.courseHoleNumber) >= startHole &&
              Number(x.courseHoleNumber) <= endHole
          )
          .reduce((sum, x) => {
            return (sum += convertToUnits(
              Number(x.teesOnHole[Number(tee.teeNumber) - 1].slopeLength),
              units
            ))
          }, 0)

        const fixedTotal = (projectCourse?.holes ?? course?.holes)
          ?.filter(
            (x) =>
              Number(x.courseHoleNumber) >= startHole &&
              Number(x.courseHoleNumber) <= endHole
          )
          .reduce(
            (sum, x) =>
              (sum += convertToUnits(
                Number(x.teesOnHole[Number(tee.teeNumber) - 1].fixedLength),
                units
              )),
            0
          )

        const scorecardTotal = (projectCourse?.holes ?? course?.holes)
          ?.filter(
            (x) =>
              Number(x.courseHoleNumber) >= startHole &&
              Number(x.courseHoleNumber) <= endHole
          )
          .reduce(
            (sum, x) =>
              (sum += convertToUnits(
                Number(x.teesOnHole[Number(tee.teeNumber) - 1].definedLength),
                units
              )),
            0
          )

        return (
          <>
            <S.RatedTableCell
              style={{
                backgroundColor: '#8b8b8b',
                fontWeight: 800,
              }}
            >
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={
                    Number(slopeTotal) !== -1
                      ? Math.round(Number(slopeTotal))
                      : ''
                  }
                />
              ) : (
                <>
                  {Number(slopeTotal) !== -1
                    ? Math.round(Number(slopeTotal))
                    : 'N/A'}
                </>
              )}
            </S.RatedTableCell>
            <S.FixedTableCell
              style={{
                backgroundColor: '#c6c6c6',
                fontWeight: 800,
              }}
            >
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={
                    Number(fixedTotal) !== -1
                      ? Math.round(Number(fixedTotal))
                      : ''
                  }
                />
              ) : (
                <>
                  {Number(fixedTotal) !== -1
                    ? Math.round(Number(fixedTotal))
                    : 'N/A'}
                </>
              )}
            </S.FixedTableCell>
            <S.ScorecardTableCell
              style={{
                backgroundColor: '#e4e4e4',
                fontWeight: 800,
              }}
            >
              {editMode ? (
                <input
                  className="w-auto text-center max-w-14"
                  value={
                    Number(scorecardTotal) !== -1
                      ? Math.round(Number(scorecardTotal))
                      : ''
                  }
                />
              ) : (
                <>
                  {Number(scorecardTotal) !== -1
                    ? Math.round(Number(scorecardTotal))
                    : 'N/A'}
                </>
              )}
            </S.ScorecardTableCell>
          </>
        )
      })}
    </tr>
  )
}

import * as S from './styled'

import { COURSES_ROUTES, TAB_MENU_ICON_STYLE } from './domain'
import {
  FiFlag,
  FiLogOut,
  FiMap,
  FiPlusCircle,
  FiSettings,
  FiUser,
  FiHelpCircle,
  FiSearch,
} from 'react-icons/fi'
import { MdAdminPanelSettings } from 'react-icons/md'
import React, { useState } from 'react'

import { AiOutlineMenu } from 'react-icons/ai'
import DrawerItem from '../../atoms/DrawerItem'
import { TouchableOpacity } from 'react-native'
import TwoButtonModal from '../TwoButtonModal'
import { signOut } from '@app/auth/domain'
import theme from '@app/ui/theme'
import { useDrawer } from './hooks'
import useUser from '@common/hooks/useUser'
import Avatar from '@app/components/atoms/Avatar'
import Spacer from '@app/components/atoms/Spacer'

export interface DrawerProps {
  navigate: (route: string, params?: {}) => void
  currentRoute: string
}

const Drawer = ({ navigate, currentRoute }: DrawerProps) => {
  const { collapsed, toggleMenu } = useDrawer()
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const user = useUser()

  if (!user?.id) return <></>

  const handleLogout = () => {
    signOut()
    setShowLogoutModal(false)
  }

  const setModal = () => setShowLogoutModal(false)
  return (
    <>
      <S.LeftMenu collapsed={!collapsed}>
        <S.LeftMenuFixedContainer>
          <S.Logo>
            <S.LogoToggler>
              <S.BurgerMenu
                onClick={(e) => {
                  e.preventDefault()
                  toggleMenu()
                }}
                className="drawer-burger-menu"
              >
                <AiOutlineMenu color={theme.colors.white} size={32} />
              </S.BurgerMenu>
            </S.LogoToggler>
            <TouchableOpacity onPress={() => navigate('Courses')}>
              <S.LogoText>Mapping Industries</S.LogoText>
            </TouchableOpacity>
          </S.Logo>
          {user && (
            <S.ProfileHeader>
              <S.AvatarContainer>
                <Avatar
                  useIndicator={false}
                  userId={user?.id?.toString()}
                  size={24}
                  userIndicatorSize={6}
                />
              </S.AvatarContainer>

              <Spacer width={4} />
              <S.NameContainer>
                <S.Name>
                  <S.UserLevel>{user?.accountType}</S.UserLevel>{' '}
                  {user?.forename} {user?.lastname}
                </S.Name>
              </S.NameContainer>
            </S.ProfileHeader>
          )}

          {user && (
            <S.LeftTabMenu>
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiFlag
                    style={TAB_MENU_ICON_STYLE(
                      COURSES_ROUTES.includes(currentRoute)
                    )}
                  />
                }
                text="Courses"
                active={COURSES_ROUTES.includes(currentRoute)}
                onPress={() => navigate('Courses')}
                className="drawer-mitem-courses"
              />
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiSearch
                    style={TAB_MENU_ICON_STYLE(
                      currentRoute === 'SearchOverview'
                    )}
                  />
                }
                text="Search Overview"
                active={currentRoute === 'SearchOverview'}
                onPress={() => navigate('SearchOverview')}
                className="drawer-mitem-map"
              />
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiMap style={TAB_MENU_ICON_STYLE(currentRoute === 'Map')} />
                }
                text="Map"
                active={currentRoute === 'Map'}
                onPress={() => navigate('Map')}
                className="drawer-mitem-map"
              />
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiHelpCircle
                    style={TAB_MENU_ICON_STYLE(currentRoute === 'Support')}
                  />
                }
                text="Support"
                onPress={() => navigate('Support')}
                active={currentRoute === 'Support'}
                className="drawer-mitem-profile"
              />
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiUser
                    style={TAB_MENU_ICON_STYLE(currentRoute === 'Teams')}
                  />
                }
                text="Profile"
                onPress={() => navigate('Teams')}
                active={currentRoute === 'Teams'}
                className="drawer-mitem-profile"
              />
              <Spacer height={20} />
              <DrawerItem
                collapsed={collapsed}
                icon={
                  <FiLogOut
                    style={TAB_MENU_ICON_STYLE()}
                    className="drawer-mitem-signout"
                  />
                }
                text="Sign Out"
                onPress={() => setShowLogoutModal(true)}
              />
              {/*{user.isAdmin && (
                <DrawerItem
                  collapsed={collapsed}
                  icon={
                    <FiSettings
                      style={TAB_MENU_ICON_STYLE(currentRoute === 'Settings')}
                    />
                  }
                  text="Settings"
                  onPress={() => navigate('Settings')}
                  active={currentRoute === 'Settings'}
                  className="drawer-mitem-settings"
                />
              )}*/}
              {Number(user.accountType) >= 10 && ( // Should only be visible to account_type
                <S.AdminTitle style={{ marginTop: 20 }}>
                  Admin{!collapsed && ` functions`}
                </S.AdminTitle>
              )}
              {Number(user.accountType) >= 10 && ( // Should only be visible to account_type
                <DrawerItem
                  collapsed={collapsed}
                  icon={
                    <FiPlusCircle
                      style={TAB_MENU_ICON_STYLE(
                        currentRoute === 'CreateSlopeTable'
                      )}
                    />
                  }
                  className="drawer-mitem-profile"
                  text="Create Slope Table"
                  onPress={() => navigate('CreateSlopeTable')}
                  active={currentRoute === 'CreateSlopeTable'}
                />
              )}
              {Number(user.accountType) >= 10 && ( // Should only be visible to account_type
                <DrawerItem
                  collapsed={collapsed}
                  icon={
                    <MdAdminPanelSettings
                      style={TAB_MENU_ICON_STYLE(currentRoute === 'Settings')}
                    />
                  }
                  text="Ratings"
                  onPress={() => navigate('AdminEvaluations')}
                  active={currentRoute === 'AdminEvaluations'}
                  className="drawer-mitem-admin"
                />
              )}
              {Number(user.accountType) >= 10 && ( // Should only be visible to account_type
                <DrawerItem
                  collapsed={collapsed}
                  icon={
                    <MdAdminPanelSettings
                      style={TAB_MENU_ICON_STYLE(currentRoute === 'Settings')}
                    />
                  }
                  text="V1 Ratings"
                  onPress={() => navigate('Admin')}
                  active={currentRoute === 'Admin'}
                  className="drawer-mitem-admin"
                />
              )}
              {/* <DrawerItem
            icon={<BsQuestionCircle style={TAB_MENU_ICON_STYLE} />}
            text="Temp"
            onPress={() => null}
            />
            <DrawerItem
            icon={<RiBookLine style={TAB_MENU_ICON_STYLE} size={22} />}
            text="Temp"
            onPress={() => null}
          /> */}
            </S.LeftTabMenu>
          )}
        </S.LeftMenuFixedContainer>
      </S.LeftMenu>
      <TwoButtonModal
        isVisible={showLogoutModal}
        title="Log out?"
        description="Are you sure you want to log out?"
        onPressLeft={setModal}
        onPressRight={handleLogout}
        leftButtonText="Cancel"
        rightButtonText="Save and log out"
      >
        <FiLogOut style={{ fontSize: '80px' }} />
      </TwoButtonModal>
    </>
  )
}

export default Drawer

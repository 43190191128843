import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  version,
} from 'react'
import firebase from 'firebase'
import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import { Eval } from '@app/evaluation-core/types'
import { useClubData } from '@app/courses/hooks'
import {
  SearchListItem,
  SearchClubData,
  SearchCourseData,
  SearchClubItemProps,
  SearchCourseItemProps,
} from './types'
import { useEvaluationFilters } from './useEvaluationFilters'

import * as S from './styled'
import { useAxios } from '@common/hooks/useAxios'
import { EvaluationDoc } from '../CreateRatingPage/CreateRatingPage'
import {
  getStatusText,
  parseCsvRow,
  normalizeName,
  parseDataRow,
  parseUniqueClubs,
} from './helpers'
import {
  CSV_COLUMNS,
  MANUAL_VERSIONS,
  STATUS_LABELS,
  UNGROUPED_COURSE_NAME,
  UNGROUPED_COURSE_ID,
  UNGROUPED_CLUB_NAME,
} from './constants'
import { utils as XLSXUtils, write as writeXLSX } from 'xlsx'
import SearchClubItem from './components/SearchClubItem'
import {
  startEvaluation,
  viewEvaluationFn,
} from '@app/evaluation-core/evaluation'
import { useSnackbar } from '@app/snackbar'
import useUser from '@common/hooks/useUser'

const SearchOverview: React.FC = () => {
  const [groupedData, setGroupedData] = useState<SearchClubData[]>([])
  const [loadingEvals, setLoadingEvals] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [expandedClubs, setExpandedClubs] = useState<Set<string>>(new Set())
  const [exportingCsv, setExportingCsv] = useState<boolean>(false)
  const [exportingExcel, setExportingExcel] = useState<boolean>(false)
  const [evaluationDocs, setEvaluationDocs] = useState<EvaluationDoc[]>([])

  const [openSnackbar] = useSnackbar()
  const user = useUser()

  const evalAxios = useAxios('/evaluations')
  const { clubsWithCourses } = useClubData()

  const getAllFSEvaluation = async () => {
    try {
      const { data } = await evalAxios.get<EvaluationDoc[]>(`/import/all`)
      return data
    } catch (error) {
      console.error('Error fetching evaluations:', error)
      return []
    }
  }

  const {
    searchTerm,
    setSearchTerm,
    selectedDistricts,
    handleDistrictSelect,
    selectedRegions,
    handleRegionSelect,
    selectedEvalStatuses,
    handleEvalStatusSelect,
    selectedFinalizedYears,
    handleFinalizedYearSelect,
    selectedEvaluationYears,
    handleEvaluationYearSelect,
    selectedManualVersions,
    handleManualVersionSelect,
    clearFilters,
    filteredGroupedData,
    includeDemoRatings,
    handleToggleDemoRatings,
  } = useEvaluationFilters({ groupedData })

  const fetchEvaluations = useCallback(async () => {
    setLoadingEvals(true)
    setError(null)

    try {
      const snapshot = await firebase
        .database()
        .ref(FBREF_COURSE_EVALUATIONS())
        .get()

      if (snapshot.exists()) {
        const data = snapshot.val() as Record<string, Eval>
        const evalsMap = new Map<string, Eval>()

        const fsEvals = await getAllFSEvaluation()
        setEvaluationDocs(fsEvals)

        Object.entries(data).forEach(([key, value]) => {
          const fsEval = fsEvals.find((e) => e.id === key)

          const checkProps = ['holeIds', 'evaluationDate']

          if (fsEval && checkProps.every((prop) => value[prop] !== undefined)) {
            let newValue = { ...value, id: key }
            if (!!fsEval.finalizedAt && !value.finalizedAt) {
              newValue = {
                ...value,
                id: key,
                // @ts-expect-error
                finalizedAt: new Date(fsEval.finalizedAt).getTime(),
              }
            }

            if (fsEval.holeIds?.every((id) => value.holeIds?.includes(id))) {
              evalsMap.set(key, newValue)
            }
          }
        })

        groupEvaluationsByClubAndCourse(evalsMap)
      } else {
        setError('No ratings found')
      }
    } catch (err) {
      console.error('Error fetching ratings:', err)
      setError('Failed to fetch ratings')
    } finally {
      setLoadingEvals(false)
    }
  }, [])

  useEffect(() => {
    console.log('fetching ratings')
    fetchEvaluations()
  }, [fetchEvaluations])

  const groupEvaluationsByClubAndCourse = useCallback(
    (evalsMap: Map<string, Eval>) => {
      const groupedArray = parseUniqueClubs(evalsMap)

      groupedArray.sort((a, b) => {
        if (a.clubName === UNGROUPED_CLUB_NAME) return 1
        if (b.clubName === UNGROUPED_CLUB_NAME) return -1
        return a.clubName.localeCompare(b.clubName)
      })

      if (clubsWithCourses) {
        groupedArray.forEach((club) => {
          const matchingClub = clubsWithCourses.find((c) => {
            if (club.clubId && c.id === club.clubId) return true
            if (normalizeName(c.name) === normalizeName(club.clubName))
              return true
            if (
              c.shortName &&
              normalizeName(c.shortName) === normalizeName(club.clubName)
            )
              return true
            return false
          })

          if (matchingClub) {
            club.districtCountry = matchingClub.districtCountry
            club.districtName = matchingClub.districtName
            club.districtRegionName = matchingClub.districtRegionName
            club.branchStatus = matchingClub.branchStatus
            club.branchId = matchingClub.branchID
            club.clubName = matchingClub.name
          }
        })
      }

      // Sort evaluations by date
      groupedArray.forEach((club) => {
        if (club.evaluations[0]) {
          club.evaluations.sort((a, b) => {
            const dateA = a.evaluationDate
              ? new Date(a.evaluationDate).getTime()
              : 0
            const dateB = b.evaluationDate
              ? new Date(b.evaluationDate).getTime()
              : 0
            return dateB - dateA
          })
        }
      })

      setGroupedData(groupedArray)
    },
    [clubsWithCourses]
  )

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
    },
    [setSearchTerm]
  )

  const handleClubToggle = useCallback((clubName: string) => {
    setExpandedClubs((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(clubName)) {
        newSet.delete(clubName)
      } else {
        newSet.add(clubName)
      }
      return newSet
    })
  }, [])

  const handleEvaluationClick = useCallback(
    async (evaluationId: string) => {
      try {
        const evalDoc = evaluationDocs.find((doc) => doc.id === evaluationId)
        if (!evalDoc) {
          console.error('Rating not found:', evaluationId)
          return
        }

        if (!user) {
          openSnackbar('You need to be logged in to view ratings', 'error')
          return
        }

        await viewEvaluationFn(evalDoc)
      } catch (error) {
        console.error('Error viewing rating:', error)
        openSnackbar('Failed to open rating', 'error')
      }
    },
    [evaluationDocs, user, openSnackbar]
  )

  const handleMarkAsDemo = useCallback(
    async (evaluationId: string, currentlyDemo: boolean) => {
      try {
        await firebase.database().ref(`/evaluations/${evaluationId}`).update({
          isDemoEvaluation: !currentlyDemo,
        })

        setGroupedData((prev) => {
          return prev.map((c) => {
            return {
              ...c,
              evaluations: c.evaluations.map((e) => {
                if (e.id === evaluationId) {
                  return { ...e, isDemoEvaluation: !currentlyDemo }
                }
                return e
              }),
            }
          })
        })
      } catch (error) {
        openSnackbar('Failed to mark as demo', 'error')
      }
    },
    []
  )

  const handleMarkAsActive = useCallback(
    async (evaluationId: string, clubId: string) => {
      try {
        const clubData = groupedData.find((c) => c.clubId === clubId)

        if (!clubData) {
          console.error('Club not found:', clubId)
          return
        }

        await firebase.database().ref(`/evaluations/${evaluationId}`).update({
          isActiveFinalized: true,
        })

        const evaluationIdsToSetNotActive = clubData.evaluations
          .filter((e) => e.id !== evaluationId)
          .map((e) => e.id)

        evaluationIdsToSetNotActive.forEach((e) => {
          firebase.database().ref(`/evaluations/${e}`).update({
            isActiveFinalized: false,
          })
        })

        setGroupedData(
          groupedData.map((c) => {
            if (c.clubId === clubId) {
              return {
                ...c,
                evaluations: c.evaluations.map((e) => {
                  if (e.id === evaluationId) {
                    return { ...e, isActiveFinalized: true }
                  }
                  return { ...e, isActiveFinalized: false }
                }),
              }
            }
            return c
          })
        )
        openSnackbar('Successfully marked as active')
      } catch (error) {
        openSnackbar('Failed to mark as active')
      }
    },
    [groupedData]
  )

  const handleExportCsv = useCallback(() => {
    if (!filteredGroupedData.length) {
      openSnackbar('No data to export')
      return
    }

    setExportingCsv(true)
    try {
      const rows: string[] = []
      rows.push(CSV_COLUMNS.join(','))

      filteredGroupedData.forEach((club) => {
        club.evaluations.forEach((evaluation) => {
          const row = parseCsvRow(club, evaluation)
          rows.push(row)
        })
      })

      const csvContent = rows.join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const name = `whs_ratings_${new Date().toISOString().split('T')[0]}.csv`
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', name)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      console.error('Error exporting CSV:', err)
      openSnackbar('Failed to export CSV')
    } finally {
      setExportingCsv(false)
    }
  }, [filteredGroupedData, openSnackbar])

  const handleExportExcel = useCallback(() => {
    if (!filteredGroupedData.length) {
      openSnackbar('No data to export')
      return
    }

    setExportingExcel(true)
    try {
      const rows: string[][] = []

      filteredGroupedData.forEach((club) => {
        club.evaluations.forEach((evaluation) => {
          const dataRow = parseDataRow(club, evaluation)
          rows.push(dataRow)
        })
      })

      const worksheet = XLSXUtils.json_to_sheet(rows)
      const workbook = XLSXUtils.book_new()
      XLSXUtils.book_append_sheet(workbook, worksheet, 'WHS_Ratings')
      const excelBuffer = writeXLSX(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const name = `whs_ratings_${new Date().toISOString().split('T')[0]}.xlsx`
      link.href = url
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      console.error('Error exporting Excel:', err)
      openSnackbar('Failed to export Excel')
    } finally {
      setExportingExcel(false)
    }
  }, [filteredGroupedData, openSnackbar])

  return (
    <S.Container>
      <S.SearchSection>
        <S.SearchInput
          type="text"
          placeholder="Search clubs..."
          value={searchTerm}
          onChange={handleSearch}
        />

        <S.FilterHeaderContainer>
          <div style={{ display: 'flex', gap: '10px' }}>
            <S.ClearFiltersButton onClick={clearFilters}>
              Clear All Filters
            </S.ClearFiltersButton>
            <S.ExportButton
              onClick={handleExportCsv}
              disabled={exportingCsv || !filteredGroupedData.length}
            >
              {exportingCsv ? 'Exporting...' : 'Export to CSV'}
            </S.ExportButton>
            <S.ExportButton
              onClick={handleExportExcel}
              disabled={exportingExcel || !filteredGroupedData.length}
            >
              {exportingExcel ? 'Exporting...' : 'Export to Excel'}
            </S.ExportButton>
          </div>
        </S.FilterHeaderContainer>

        <S.FiltersContainer>
          <S.SingleColumnFilterSection>
            <S.FilterSectionTitle>Districts</S.FilterSectionTitle>
            <S.FilterPillsContainer>
              {loadingEvals ? (
                <S.FilterPill selected={false} onClick={() => {}}>
                  Loading...
                </S.FilterPill>
              ) : (
                Array.from(
                  new Set(
                    groupedData
                      .map((club) => club.districtName)
                      .filter(Boolean) as string[]
                  )
                )
                  .sort()
                  .map((district) => (
                    <S.FilterPill
                      key={district}
                      selected={selectedDistricts.includes(district)}
                      onClick={() => handleDistrictSelect(district)}
                    >
                      {district}
                    </S.FilterPill>
                  ))
              )}
            </S.FilterPillsContainer>
          </S.SingleColumnFilterSection>

          <S.FilterColumnsContainer>
            <S.FilterSection>
              <S.FilterSectionTitle>Regions</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                {loadingEvals ? (
                  <S.FilterPill selected={false} onClick={() => {}}>
                    Loading...
                  </S.FilterPill>
                ) : (
                  Array.from(
                    new Set(
                      groupedData
                        .map((club) => club.districtRegionName)
                        .filter(Boolean) as string[]
                    )
                  )
                    .sort()
                    .map((region) => (
                      <S.FilterPill
                        key={region}
                        selected={selectedRegions.includes(region)}
                        onClick={() => handleRegionSelect(region)}
                      >
                        {region}
                      </S.FilterPill>
                    ))
                )}
              </S.FilterPillsContainer>
            </S.FilterSection>

            <S.FilterSection>
              <S.FilterSectionTitle>Rating Status</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                {Object.entries(STATUS_LABELS).map(([status, label]) => (
                  <S.FilterPill
                    key={status}
                    selected={selectedEvalStatuses.includes(Number(status))}
                    onClick={() => handleEvalStatusSelect(Number(status))}
                  >
                    {label.label}
                  </S.FilterPill>
                ))}
              </S.FilterPillsContainer>
            </S.FilterSection>

            <S.FilterSection>
              <S.FilterSectionTitle>Finalized Year</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                {loadingEvals ? (
                  <S.FilterPill selected={false} onClick={() => {}}>
                    Loading...
                  </S.FilterPill>
                ) : (
                  Array.from(
                    new Set(
                      groupedData
                        .flatMap((club) =>
                          club.evaluations
                            .filter((e) => e.finalizedAt)
                            .map((e) =>
                              new Date(e.finalizedAt!).getFullYear().toString()
                            )
                        )
                        .filter(Boolean)
                    )
                  )
                    .sort()
                    .reverse()
                    .map((year) => (
                      <S.FilterPill
                        key={year}
                        selected={selectedFinalizedYears.includes(year)}
                        onClick={() => handleFinalizedYearSelect(year)}
                      >
                        {year}
                      </S.FilterPill>
                    ))
                )}
              </S.FilterPillsContainer>
            </S.FilterSection>

            <S.FilterSection>
              <S.FilterSectionTitle>Rated Year</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                {loadingEvals ? (
                  <S.FilterPill selected={false} onClick={() => {}}>
                    Loading...
                  </S.FilterPill>
                ) : (
                  Array.from(
                    new Set(
                      groupedData
                        .flatMap((club) =>
                          club.evaluations
                            .filter((e) => e.evaluationDate)
                            .map((e) =>
                              new Date(e.evaluationDate!)
                                .getFullYear()
                                .toString()
                            )
                        )
                        .filter(Boolean)
                    )
                  )
                    .sort()
                    .reverse()
                    .map((year) => (
                      <S.FilterPill
                        key={year}
                        selected={selectedEvaluationYears.includes(year)}
                        onClick={() => handleEvaluationYearSelect(year)}
                      >
                        {year}
                      </S.FilterPill>
                    ))
                )}
              </S.FilterPillsContainer>
            </S.FilterSection>

            <S.FilterSection>
              <S.FilterSectionTitle>Manual Version</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                {MANUAL_VERSIONS.map((version) => (
                  <S.FilterPill
                    key={version}
                    selected={selectedManualVersions.includes(
                      version.toString()
                    )}
                    onClick={() =>
                      handleManualVersionSelect(version.toString())
                    }
                  >
                    {version}
                  </S.FilterPill>
                ))}
              </S.FilterPillsContainer>
            </S.FilterSection>

            <S.FilterSection>
              <S.FilterSectionTitle>Demo Ratings</S.FilterSectionTitle>
              <S.FilterPillsContainer>
                <S.FilterPill
                  key="include-demo-ratings"
                  selected={includeDemoRatings}
                  onClick={handleToggleDemoRatings}
                >
                  Include Demo Ratings
                </S.FilterPill>
              </S.FilterPillsContainer>
            </S.FilterSection>
          </S.FilterColumnsContainer>
        </S.FiltersContainer>
      </S.SearchSection>

      <S.ListContainer>
        {loadingEvals ? (
          <S.LoadingIndicator>Loading Ratings...</S.LoadingIndicator>
        ) : error ? (
          <S.ErrorMessage>{error}</S.ErrorMessage>
        ) : filteredGroupedData.length === 0 ? (
          <S.EmptyState>
            {searchTerm ? 'No clubs match your search' : 'No clubs found'}
          </S.EmptyState>
        ) : (
          <S.HierarchicalList>
            {filteredGroupedData.map((club) => (
              <SearchClubItem
                key={club.clubId || club.clubName}
                club={club}
                isAdminUser={user?.isAdmin}
                isExpanded={expandedClubs.has(club.clubName)}
                onToggle={handleClubToggle}
                onEvaluationClick={handleEvaluationClick}
                onMarkAsDemo={handleMarkAsDemo}
                onMarkAsActive={handleMarkAsActive}
              />
            ))}
          </S.HierarchicalList>
        )}
      </S.ListContainer>
    </S.Container>
  )
}

export default SearchOverview

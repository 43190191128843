import React, { useState } from 'react'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import {
  AdminTitle,
  BorderlessDropDownMenuItem,
  DropDownMenuItem,
} from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { Eval, LockStatus, TeamRole } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { resetHole } from '@app/evaluation-core'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import {
  EvaluationDoc,
  EvaluationDocWithForms,
} from '../../pages/CompositeCoursePage/CompositeCoursePage'
import { ResetHoleConfirmationModal } from './ResetHoleConfirmationModal'
import { useSnackbar } from '@app/snackbar'
import {
  FaTrash,
  FaTable,
  FaSync,
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from 'react-icons/fa'

interface EvaluationsListActionMenuProps {
  evaluation: any
  latestForms: any
  isActiveFinalized: boolean
  isDemoEvaluation: boolean
  onEvaluationInfo: () => void
  onRegenerateForms: () => void
  onDownloadForms: () => void
  onDeleteEvaluation: () => void
  onResetUnlockedHoles: () => void
  onResetRatingRevision: () => void
  onToggleShowForms: (evaluationId: string) => void
  onMarkAsActive?: (evaluationId: string) => void
  onMarkAsDemo?: (evaluationId: string, isCurrentlyDemo: boolean) => void
}

export const EvaluationsListActionMenu = ({
  evaluation,
  latestForms,
  isActiveFinalized,
  isDemoEvaluation,
  onEvaluationInfo,
  onRegenerateForms,
  onDownloadForms,
  onDeleteEvaluation,
  onResetUnlockedHoles,
  onResetRatingRevision,
  onToggleShowForms,
  onMarkAsActive,
  onMarkAsDemo,
}: EvaluationsListActionMenuProps) => {
  const [openSnackbar] = useSnackbar()
  const evalUser = useEvaluationUser()

  const [showResetHoleCancelModal, setShowResetHoleCancelModal] =
    useState(false)

  const closeResetHoleModal = () => setShowResetHoleCancelModal(false)
  const openResetHoleModal = () => setShowResetHoleCancelModal(true)

  const resetHoleFn = async () => {
    const realTimeEval = evaluation as Eval

    realTimeEval?.holes.forEach(async (hole) => {
      if (!realTimeEval?.id || !hole?.holeIndex) {
        console.log(`resetHoleGn - missing realTimeEval?.id or hole.holeIndex`)
        return
      }

      if (hole?.locked == LockStatus.UNLOCKED)
        await resetHole(+hole?.holeIndex + 1, realTimeEval?.id)
    })
    closeResetHoleModal()
  }

  function isEvaluationWithForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    const formsEval = evaluation as EvaluationDocWithForms
    return formsEval.finalizedForms !== undefined
  }

  function isEvaluationWithActualForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    const formsEval = evaluation as EvaluationDocWithForms
    return (
      formsEval.finalizedForms !== undefined &&
      formsEval.finalizedForms?.length > 1 - 1
    )
  }

  function isEvaluationWithMultipleForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    const formsEval = evaluation as EvaluationDocWithForms
    return (
      formsEval.finalizedForms !== undefined &&
      formsEval.finalizedForms?.length > 1
    )
  }

  const isAuthenticatedSystemAdmin = evalUser?.isAdmin

  return (
    <>
      <ResetHoleConfirmationModal
        closeModal={closeResetHoleModal}
        showModal={showResetHoleCancelModal}
        acceptFunction={resetHoleFn}
      />
      <DropDownMenu
        value={''}
        isMenu={true}
        width={250}
        relative
        xAdj={-150}
        useShadow={false}
      >
        {
          <BorderlessDropDownMenuItem
            onPress={async () => {
              openSnackbar(
                'This function has been temporarily disabled because of inconsistency with data until /Stoffe'
              )
            }}
          >
            {/*<RatingListInfoDropdown evaluation={evaluation} />*/}
            <FormExGenderBtn>
              <span>Detailed info</span>
              <div>
                <FaInfoCircle size={20} color={'#4190e0'} />
              </div>
            </FormExGenderBtn>
          </BorderlessDropDownMenuItem>
        }

        <>
          <AdminTitle>Team leader functions</AdminTitle>

          <DropDownMenuItem
            disabled={
              !isAuthenticatedSystemAdmin &&
              !evalUser?.isTeamLeader &&
              !isActiveFinalized
            }
            onPress={() => {
              onMarkAsDemo?.(evaluation.id, isDemoEvaluation)
            }}
          >
            <FormExGenderBtn>
              <span>
                {isDemoEvaluation ? 'Currently DEMO' : 'Mark as DEMO'}
              </span>
              <div>
                <FaExclamationCircle size={20} color={'orange'} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>

          {evaluation?.status === EvalStatus.FINALIZED && (
            <DropDownMenuItem
              disabled={
                !isAuthenticatedSystemAdmin &&
                !evalUser?.isTeamLeader &&
                !isActiveFinalized
              }
              onPress={() => onMarkAsActive?.(evaluation.id)}
            >
              <FormExGenderBtn>
                <span>
                  {isActiveFinalized ? 'Currently Active' : 'Mark as Active'}
                </span>
                <div>
                  <FaCheckCircle size={20} color={'green'} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          )}

          {evaluation?.status === EvalStatus.FINALIZED &&
            isEvaluationWithMultipleForms(evaluation) && (
              <DropDownMenuItem
                disabled={
                  !isAuthenticatedSystemAdmin && !evalUser?.isTeamLeader
                }
                onPress={() => onToggleShowForms(evaluation.id)}
              >
                <FormExGenderBtn>
                  <span>Toggle show generated forms</span>
                  <div>
                    <FaTable size={20} color={theme.colors.primary} />
                  </div>
                </FormExGenderBtn>
              </DropDownMenuItem>
            )}

          {evaluation?.status === EvalStatus.FINALIZED && (
            <DropDownMenuItem
              disabled={!isAuthenticatedSystemAdmin && !evalUser?.isTeamLeader}
              onPress={onRegenerateForms}
            >
              <FormExGenderBtn>
                <span>
                  {isEvaluationWithActualForms(evaluation)
                    ? 'Regenerate'
                    : 'Generate'}{' '}
                  forms
                </span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          )}

          <DropDownMenuItem
            disabled={!isAuthenticatedSystemAdmin && !evalUser?.isTeamLeader}
            onPress={onDeleteEvaluation}
          >
            <FormExGenderBtn>
              <span>Delete</span>
              <div>
                <FaTrash size={20} color={'rgb(238, 0, 0)'} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>
        </>

        {evaluation?.status !== EvalStatus.FINALIZED && evalUser?.isMiAdmin && (
          <>
            <AdminTitle>Admin functions</AdminTitle>

            <DropDownMenuItem
              disabled={!evalUser?.isMiAdmin}
              onPress={openResetHoleModal}
            >
              <FormExGenderBtn>
                <span>Reset unlocked holes</span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          </>
        )}
        {evaluation?.status !== EvalStatus.FINALIZED &&
          evalUser?.isMiAdmin &&
          evaluation?.ratingRevision && (
            <>
              <DropDownMenuItem
                disabled={!evalUser?.isMiAdmin}
                onPress={onResetRatingRevision}
              >
                <FormExGenderBtn>
                  <span>Reset rating revision</span>
                  <div>
                    <FaSync size={20} color={theme.colors.primary} />
                  </div>
                </FormExGenderBtn>
              </DropDownMenuItem>
            </>
          )}
      </DropDownMenu>
    </>
  )
}

import theme from '@app/ui/theme'
import styled from 'styled-components/native'
import _styled from 'styled-components'

export const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin: 25px auto;
`
export const MenuDiv = styled.View`
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
`
export const ButtonContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`
export const TeeButton = styled.TouchableOpacity<{ selected: boolean }>`
  background: ${({ selected }) =>
    selected ? theme.colors.primary : '#c4c4c4'};
  height: 40px;
  width: 40px;
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
`

export const TeeContainer = styled.View`
  background-color: ${theme.colors.white};
  border-radius: 15;
  justify-content: center;
  width: 420px;
`

export const InnerTeeContianer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const TitleText = styled.Text`
  font-weight: bold;
  font-size: 20px;
`

interface DropDownMenuItemProps {
  disabled?: boolean
}

export const MenuItem = styled.TouchableOpacity`
  display: block;
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
  opacity: ${(props: DropDownMenuItemProps) => (props.disabled ? '0.6' : 1)};
`

export const FormExGenderBtn = styled.View`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  & > div {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
  & > div > div {
    width: 30px;
    text-align: center;
  }
`

export const StyledInput = _styled.textarea`
  padding: 5px;
  width: 97%;
  height: 72px;
  border: none;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.dark1};
  border-radius: ${theme.borderRadius}px;
  outline: none;
  font-size: 16px;
  font-family: 'Proxima Nova';
  color: #333;
  margin-right: 10px;
  -webkit-appearance: none;
  appearance: none;
`

export const BaseAlertContainer = styled.View`
  display: flex;
  margin: 16px 0;
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
`

export const AlertIconContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`

export const AlertTitle = styled.Text`
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  flex: 1;
  line-height: 24px;
`

export const AlertDescription = styled.Text`
  font-family: 'Proxima Nova';
  font-size: 14px;
  margin-left: 32px;
  line-height: 20px;
  opacity: 0.9;
`

export const AlertList = styled.View`
  margin: 12px 0 0 32px;
`

export const AlertListItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const AlertListText = styled.Text`
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 20px;
`

export const CourseLengthWarningContainer = styled(BaseAlertContainer)`
  background-color: #fffbeb;
  border: 1px solid #fbbf24;

  ${AlertTitle} {
    color: #92400e;
  }

  ${AlertDescription}, ${AlertListText} {
    color: #92400e;
  }
`

export const CourseLengthErrorContainer = styled(BaseAlertContainer)`
  background-color: rgb(239, 218, 218);
  border: 1px solid #f87171;
`

import { Eval } from '@app/evaluation-core/types'
import { SearchCourseData, SearchClubData } from './types'
import { UNGROUPED_COURSE_NAME } from './constants'
import { UNGROUPED_COURSE_ID } from './constants'
import { UNGROUPED_CLUB_NAME } from './constants'

export const parseCsvStringValue = (value?: string | null) =>
  value?.toString().replace(/,/g, ';').replace(/\n/g, ' ') || ''

export const parseCsvDateValue = (value?: string | number | null) =>
  value ? new Date(value).toISOString().split('T')[0] : ''

export const normalizeName = (name: string): string => name.toLowerCase().trim()

export const parseDataRow = (
  club: SearchClubData,
  evaluation: Eval & { courseName?: string }
): string[] => {
  return [
    parseCsvStringValue(club.clubName),
    parseCsvStringValue(club.clubId),
    parseCsvStringValue(club.districtName),
    parseCsvStringValue(club.districtRegionName),
    parseCsvStringValue(evaluation.courseName),
    parseCsvStringValue(evaluation.id),
    parseCsvStringValue(evaluation.saveName),
    getStatusText(evaluation.status),
    parseCsvStringValue(evaluation.manualVersion),
    parseCsvDateValue(evaluation.evaluationDate),
    parseCsvDateValue(evaluation.finalizedAt),
    parseCsvStringValue(evaluation.description),
  ]
}

export const parseCsvRow = (
  club: SearchClubData,
  evaluation: Eval & { courseName?: string }
) => {
  return [
    parseCsvStringValue(club.clubName),
    parseCsvStringValue(club.clubId),
    parseCsvStringValue(club.districtName),
    parseCsvStringValue(club.districtRegionName),
    parseCsvStringValue(evaluation.courseName),
    parseCsvStringValue(evaluation.id),
    parseCsvStringValue(evaluation.saveName),
    getStatusText(evaluation.status),
    parseCsvStringValue(evaluation.manualVersion),
    parseCsvDateValue(evaluation.evaluationDate),
    parseCsvDateValue(evaluation.finalizedAt),
    parseCsvStringValue(evaluation.description),
  ]
    .map((value) => `"${value}"`)
    .join(',')
}

export const getStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return 'Not Started'
    case 1:
      return 'Preparing'
    case 2:
      return 'In Progress'
    case 3:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}

export const parseUniqueClubs = (evalsMap: Map<string, Eval>) => {
  const clubByIdMap = new Map<string, SearchClubData>()
  const clubByNameMap = new Map<string, SearchClubData>()

  // Create an ungrouped club for evaluations without club info
  const ungroupedClub: SearchClubData = {
    clubId: undefined,
    clubName: UNGROUPED_CLUB_NAME,
    evaluations: [],
  }
  clubByNameMap.set(UNGROUPED_CLUB_NAME.toLowerCase(), ungroupedClub)

  for (const evaluation of evalsMap.values()) {
    let club: SearchClubData | undefined

    // Leave out Hooks Demo as it is a demo club (old bug where the club has 2 ids)
    if (evaluation.clubId === 1409 || evaluation.clubId === 1408) {
      continue
    }

    if (evaluation.clubId && clubByIdMap.has(String(evaluation.clubId))) {
      club = clubByIdMap.get(String(evaluation.clubId))
    } else if (evaluation.clubName) {
      const normalizedClubName = normalizeName(evaluation.clubName)
      if (clubByNameMap.has(normalizedClubName)) {
        club = clubByNameMap.get(normalizedClubName)
      }
    }

    if (!club) {
      club = {
        clubId: evaluation.clubId ? String(evaluation.clubId) : undefined,
        branchId: evaluation.branchId,
        clubName: evaluation.clubName || `Club ID: ${evaluation.clubId}`,
        evaluations: [],
      }

      if (evaluation.clubId) {
        clubByIdMap.set(String(evaluation.clubId), club)
      }

      if (evaluation.clubName) {
        clubByNameMap.set(normalizeName(evaluation.clubName), club)
      }
    }

    const evalWithCourseName = {
      ...evaluation,
      courseName: evaluation.courseName || 'Unknown Course',
    }

    club.evaluations.push(evalWithCourseName)
  }

  return Array.from(clubByIdMap.values())
}

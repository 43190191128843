import styled from 'styled-components'
export const Container = styled.div`
  padding: 1rem;
`

export const TeeGrid = styled.div`
  display: flex;
  gap: 1rem;
`
export const ColorInput = styled.input.attrs({ type: 'color' })`
  width: 100%;
  height: 32px;

  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-in-out;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 0.5rem;
  }
  &::-moz-color-swatch {
    border: none;
    border-radius: 0.5rem;
  }
`

export const NameInput = styled.input.attrs({ type: 'text' })`
  width: 100%;
  padding: 4px;
  border: 1px solid #e2e8f0;
`

export const TeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  align-items: center;
  gap: 0.5rem;
`

export const ButtonText = styled.span`
  color: white;
`

// export const ColorInput = styled.input.attrs({ type: 'color' })`
//   width: 3rem;
//   height: 3rem;
//   border-radius: 0.5rem;
//   cursor: pointer;
//   border: 1px solid #e2e8f0;
//   border-opacity: 0.5;
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
//   transition: box-shadow 0.2s ease-in-out;
//
//   &:hover {
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   }
//
//   /* Remove default color input styles */
//   &::-webkit-color-swatch-wrapper {
//     padding: 0;
//   }
//   &::-webkit-color-swatch {
//     border: none;
//     border-radius: 0.5rem;
//   }
//   &::-moz-color-swatch {
//     border: none;
//     border-radius: 0.5rem;
//   }
// `

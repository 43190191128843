import { useCallback, useEffect } from 'react'
import { Eval } from '@app/evaluation-core/types'
import { useState } from 'react'
import { getMinCourseLength } from '../utils/getMinCourseLength'
import firebase from 'firebase/app'
type TeeDiff = {
  tee: string
  diff: number | null
  actualLength: number
  minLength: number
}
export const useCourseLengthCheck = (evaluation: Eval) => {
  const [invalidCourseLength, setInvalidCourseLength] = useState<
    TeeDiff[] | null
  >(null)

  const calculateCourseLength = useCallback(
    (adjustments: any) => {
      const n: TeeDiff[] = []
      const teeMap = new Map<number, number>()
      const teeLength = evaluation.holes[0].tees.length

      if (!adjustments?.adjustment?.holes) {
        return null
      }

      for (let i = 0; i < teeLength; i++) {
        teeMap.set(i + 1, 0)
      }

      for (let i = 0; i < evaluation.holes.length; i++) {
        const hole = evaluation.holes[i]
        const adjHole = adjustments.adjustment.holes[i]
        for (const tee of hole.tees) {
          const key = `tee${tee.teeNumber}Length_HOLE_${i}`
          const len = adjHole[key] ?? 0
          const val = teeMap.get(tee.teeNumber) + len
          teeMap.set(tee.teeNumber, val)
        }
      }

      for (let i = 0; i < teeLength; i++) {
        const tee = evaluation.holes[0].tees[i]
        const len = teeMap.get(tee.teeNumber)!

        const minLength = getMinCourseLength(
          evaluation.manualVersion,
          evaluation.holes.length
        )

        if (minLength && len < minLength) {
          const invalidTee = {
            tee: tee.name,
            diff: minLength - len,
            actualLength: len,
            minLength,
          }
          n.push(invalidTee)
        }
      }
      return n
    },
    [evaluation]
  )

  useEffect(() => {
    const db = firebase.database()
    const ref = db.ref(`adjustments/${evaluation.id}`)

    ref.get().then((snapshot) => {
      if (snapshot.exists()) {
        if (invalidCourseLength?.length === 0) {
          const adjustments = snapshot.val()
          const l = calculateCourseLength(adjustments)
          setInvalidCourseLength(l)
        }
      }
    })

    ref.on('value', (snapshot) => {
      if (snapshot.exists() && window.location.pathname.endsWith('Forms')) {
        const adjustments = snapshot.val()
        const l = calculateCourseLength(adjustments)
        setInvalidCourseLength(l)
      }
    })

    return () => {
      ref.off()
      setInvalidCourseLength(null)
    }
  }, [evaluation.id])

  return invalidCourseLength
}

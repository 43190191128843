import React from 'react'
import * as S from './styled'
import { FaExclamationCircle } from 'react-icons/fa'
import {
  resetEvaluationRatingRevision,
  startEvaluation,
  viewEvaluationFn,
} from '@app/evaluation-core/evaluation'
import { LRClub } from '../../../types/Clubs'
import { InLineSpinner } from '@app/evaluation/components/Spinner'
import {
  EvaluationDoc,
  EvaluationDocWithForms,
} from '../../../pages/CreateRatingPage/CreateRatingPage'

import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import {
  EvalStatus,
  evaluationButtonText,
} from '@app/courses/domain/ratingStatus'
import { downloadFolder } from './FinalRatingItem'
import { MiProjectBranch } from '../../../types/Project'
import { useApi } from '@common/hooks/useApi'
import { useAxios } from '@common/hooks/useAxios'
import { useSnackbar } from '@app/snackbar'
import { useTheme } from 'styled-components'
import { Theme } from '@app/ui/theme'
import useUser from '@common/hooks/useUser'
import TwoButtonModal from '../../organisms/TwoButtonModal'
import DateTimeView from '../../atoms/DateTimeView'
import HideOnTablet from './HideOnTablet'
import {
  Eval,
  EvaluationUser,
  MIHole,
  TeamRole,
} from '@app/evaluation-core/types'
import EvalStatusIndicator from '../CoursesList/EvalStatusIndicator'
import { EvaluationsListActionMenu } from '@app/evaluation/components/EvaluationsListActionMenu'
import { ActivityIndicator } from 'react-native'
import firebase from 'firebase'

type Props = {
  club: LRClub
  evaluations: EvaluationDoc[] | EvaluationDocWithForms[] | undefined
  realTimeEvals?: Map<string, Eval>
  loading: boolean
  templateId?: string
  noDataText: string
  deleteAction?: (evaluationId: string) => Promise<void>
}

const convertStatusToText = (status?: number) => {
  switch (true) {
    case status === EvalStatus.NOT_STARTED:
      return 'Not Started'
    case status === EvalStatus.PREPARING:
      return 'Preparing'
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}

const InProgressEvaluationsList: React.FC<Props> = ({
  evaluations,
  realTimeEvals,
  deleteAction,
  loading,
  club,
  noDataText,
  templateId,
}) => {
  const axios = useAxios('/ratings')
  const theme = useTheme() as Theme
  const user = useUser()
  const [showFinalizedForms, setShowFinalizedForms] = React.useState<string[]>(
    []
  ) // list of evaluations that are expanded by user to show multiple forms
  const [showId, setShowId] = React.useState(false)
  const [selectedEval, setSelectedEval] = React.useState<string>('')
  const [
    showDeleteEvaluationConfirmModalVisible,
    setShowDeleteEvaluationConfirmModalVisible,
  ] = React.useState(false)
  const [
    showResetEvaluationRatingRevisionConfirmModalVisible,
    setShowResetEvaluationRatingRevisionConfirmModalVisible,
  ] = React.useState(false)
  const [showCreateRevisionModal, setShowCreateRevisionModal] =
    React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [createRatingLoading, setCreateRatingLoading] = React.useState(false)
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: theme.colors.warning,
    },
  })

  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  if (loading || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  if (!evaluations || (evaluations?.length <= 0 && !loading)) {
    return <span>{noDataText}</span>
  }

  const createRating = async (evaluationId: string) => {
    if (createRatingLoading) {
      return openSnackbar(
        <div>
          <span>Please wait for the previous rating to be generated.</span>
        </div>
      )
    }
    setCreateRatingLoading(true)
    if (!templateId) return
    try {
      await axios.post<void>(`/templates/${templateId}/generate`, {
        evaluationId,
      })
    } finally {
      setCreateRatingLoading(false)
    }
  }

  const deleteEvaluationFn = async (evaluationId: string) => {
    if (isDeleting) return
    setIsDeleting(true)
    if (typeof deleteAction !== 'function') return
    try {
      await deleteAction(evaluationId)
    } finally {
      setIsDeleting(false)
    }
    if (showDeleteEvaluationConfirmModalVisible) {
      setShowDeleteEvaluationConfirmModalVisible(false)
    }
  }

  const resetEvaluationRatingRevisionFn = async (evaluationId: string) => {
    await resetEvaluationRatingRevision(evaluationId)

    if (showResetEvaluationRatingRevisionConfirmModalVisible) {
      setShowResetEvaluationRatingRevisionConfirmModalVisible(false)
    }
  }

  const continueOrCreateRevisionClickFn = async (evaluation: EvaluationDoc) => {
    if (evaluation.status == EvalStatus.FINALIZED) {
      // When pressing Create Revision

      setShowCreateRevisionModal(true)
    } else await continueOrCreateRevision(evaluation)
  }

  const continueOrCreateRevision = async (evaluation: EvaluationDoc) => {
    try {
      await startEvaluation(
        +club.id,
        club.branchID,
        evaluation.holeIds,
        {
          ...evaluation.ratingRevision,
          parentEvaluation: evaluation.id,
        },
        evaluation
      )

      setShowCreateRevisionModal(false)
    } catch (err) {
      let msg = err

      if (err === 'not_member' || err === 'not_member_revision')
        msg = 'You are not member of this rating and cannot open it!'

      openSnackbar(msg)
    }
  }

  const onMarkAsDemo = async (
    evaluationId: string,
    isCurrentlyDemo: boolean
  ) => {
    try {
      await firebase.database().ref(`/evaluations/${evaluationId}`).update({
        isDemoEvaluation: !isCurrentlyDemo,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const showDeleteEvaluationConfirmationModal = (evaluationId: string) => {
    setShowDeleteEvaluationConfirmModalVisible(true)
    setSelectedEval(evaluationId)
  }

  const showResetEvaluationRatingRevisionConfirmationModal = (
    evaluationId: string
  ) => {
    setShowResetEvaluationRatingRevisionConfirmModalVisible(true)
    setSelectedEval(evaluationId)
  }

  const getFSTeamLeader = (evaluation: any) =>
    evaluation?.users?.find((user) => user.role === 1)?.email ||
    evaluation?.users?.find((user) => user.role === 0)?.email

  const getRTTeamLeader = (evaluation: Eval | undefined) => {
    if (!evaluation) return [] as EvaluationUser[]

    const entries = Object.values(evaluation?.users)
    return (
      entries.find((evalUser) => evalUser.role === TeamRole.LEADER)?.view
        ?.email ||
      entries.find((evalUser) => evalUser.role === TeamRole.SYSTEM_ADMIN)?.view
        ?.email
    )
  }

  const hasFinalized = evaluations.some((e) => e.finalizedAt)

  const mappedEvaluations: [
    EvaluationDoc | EvaluationDocWithForms,
    Eval | undefined
  ][] = evaluations.reduce((acc, e) => {
    const realTimeEvaluation = realTimeEvals?.get(e.id)
    if (realTimeEvaluation?.isDemoEvaluation && !user.isAdmin) {
      return acc
    }
    return [...acc, [e, realTimeEvaluation]]
  }, [] as [EvaluationDoc | EvaluationDocWithForms, Eval | undefined][])

  const filterHolesByLockedStatus = (evaluation?: Eval) =>
    evaluation?.holes?.reduce(
      (acc, hole) => {
        if (hole.locked !== 0) {
          acc.lockedHoles.push(hole)
        } else {
          acc.unlockedHoles.push(hole)
        }
        return acc
      },
      { lockedHoles: [] as MIHole[], unlockedHoles: [] as MIHole[] }
    ) || { lockedHoles: [], unlockedHoles: [] }

  return (
    <S.TableContainer>
      <S.Table>
        <thead>
          <S.TableRowHead onClick={() => user.isAdmin && setShowId(!showId)}>
            <S.TableHeadCol style={{ width: '15%', minWidth: '100px' }}>
              {showId ? 'ID' : 'Name'}
            </S.TableHeadCol>
            {hasFinalized && (
              <HideOnTablet>
                <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
                  Finalized Date
                </S.TableHeadCol>
              </HideOnTablet>
            )}
            {!hasFinalized && (
              <HideOnTablet>
                <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
                  Rating Status
                </S.TableHeadCol>
              </HideOnTablet>
            )}
            <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
              Rating Date
            </S.TableHeadCol>
            <HideOnTablet>
              <S.TableHeadCol style={{ width: '15%' }}>
                Team Leader
              </S.TableHeadCol>
            </HideOnTablet>
            <S.TableHeadCol style={{ width: '5%', minWidth: '50px' }}>
              Revision
            </S.TableHeadCol>
            <S.TableHeadCol style={{ width: '5%', minWidth: '50px' }}>
              {!hasFinalized ? 'Holes\nlocked' : 'Holes'}
              {/* TODO Line break not working / Stoffe */}
            </S.TableHeadCol>
            <HideOnTablet>
              <S.TableHeadCol style={{ width: '5%' }}>
                Manual Version
              </S.TableHeadCol>
            </HideOnTablet>
            <S.TableHeadCol style={{ width: '30%' }} />
          </S.TableRowHead>
        </thead>
        <tbody>
          {mappedEvaluations.map(([evaluation, realTimeEvaluation], i) => {
            const latestForms =
              (evaluation as EvaluationDocWithForms).finalizedForms &&
              (evaluation as EvaluationDocWithForms).finalizedForms
                ?.sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .find((x) => true)

            const evalActionsMenu = (
              <EvaluationsListActionMenu
                isActiveFinalized={
                  realTimeEvaluation?.isActiveFinalized ?? false
                }
                isDemoEvaluation={realTimeEvaluation?.isDemoEvaluation ?? false}
                onMarkAsDemo={onMarkAsDemo}
                evaluation={
                  evaluation?.status === EvalStatus.FINALIZED
                    ? evaluation
                    : realTimeEvaluation
                }
                latestForms={latestForms}
                onEvaluationInfo={() => {
                  return
                }}
                onRegenerateForms={async () =>
                  await createRating(evaluation.id)
                }
                onDownloadForms={async () =>
                  latestForms && (await downloadFolder(latestForms.zipPath))
                }
                onDeleteEvaluation={() => {
                  showDeleteEvaluationConfirmationModal(evaluation.id)
                }}
                onResetRatingRevision={() => {
                  showResetEvaluationRatingRevisionConfirmationModal(
                    evaluation.id
                  )
                }}
                onResetUnlockedHoles={() => {
                  return
                }}
                onToggleShowForms={(evaluationId) => {
                  if (showFinalizedForms.find((i) => i === evaluationId))
                    setShowFinalizedForms(
                      showFinalizedForms.filter((i) => i !== evaluationId)
                    )
                  else {
                    const newList = showFinalizedForms.filter((x) => true)

                    newList.push(evaluationId)

                    setShowFinalizedForms([])
                    setShowFinalizedForms(newList)
                  }
                }}
              />
            )

            if (realTimeEvals && !realTimeEvaluation) {
              return (
                <React.Fragment key={`${evaluation.saveName}-${i}`}>
                  <S.TableRow>
                    <S.TableCol style={{ alignItems: 'start' }}>
                      <ActivityIndicator color={theme.colors.secondary2} />
                    </S.TableCol>
                    <S.TableCol />
                    <S.TableCol />
                    <S.TableCol />
                    <S.TableCol />
                    <S.TableCol />
                    <S.TableCol />
                    <S.TableCol>
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          flex: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        {evalActionsMenu}
                        <RoundedButton
                          isDefault={evaluation.status !== EvalStatus.FINALIZED}
                          style={{
                            width: '100%',
                            maxWidth: 180,
                          }}
                          title={evaluationButtonText(evaluation.status)}
                          onPress={async () =>
                            await continueOrCreateRevisionClickFn(evaluation)
                          }
                        />{' '}
                      </div>
                    </S.TableCol>
                  </S.TableRow>
                </React.Fragment>
              )
            }

            const { lockedHoles, unlockedHoles } =
              filterHolesByLockedStatus(realTimeEvaluation)

            return (
              <React.Fragment
                key={`${
                  realTimeEvaluation?.saveName || evaluation.saveName
                }-${i}`}
              >
                <S.TableRow>
                  <S.TableCol>
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        alignItems: 'center',
                      }}
                    >
                      {realTimeEvaluation?.isDemoEvaluation && (
                        <FaExclamationCircle color={'orange'} />
                      )}
                      {showId
                        ? evaluation.id
                        : realTimeEvaluation?.saveName ||
                          evaluation.saveName ||
                          '-'}
                    </div>
                  </S.TableCol>
                  {!hasFinalized && (
                    <HideOnTablet>
                      <S.TableCol>
                        <EvalStatusIndicator
                          status={
                            realTimeEvaluation?.status ?? evaluation.status
                          }
                          finalizedAt={evaluation?.finalizedAt}
                        />
                      </S.TableCol>
                    </HideOnTablet>
                  )}
                  {hasFinalized && (
                    <HideOnTablet>
                      <S.TableCol>
                        <DateTimeView dateString={evaluation.finalizedAt} />
                      </S.TableCol>
                    </HideOnTablet>
                  )}
                  <S.TableCol>
                    <DateTimeView
                      dateString={
                        realTimeEvaluation?.evaluationDate ||
                        evaluation.ratingRevision.ratingDate
                      }
                      showTime={false}
                    />
                  </S.TableCol>
                  <HideOnTablet>
                    <S.TableCol>
                      {realTimeEvaluation
                        ? getRTTeamLeader(realTimeEvaluation)
                        : getFSTeamLeader(evaluation)}
                    </S.TableCol>
                  </HideOnTablet>
                  <S.TableColEnd>
                    <S.ClubHoleCount
                      style={{ borderRadius: '8px', margin: '0px' }}
                    >
                      {evaluation?.status === EvalStatus.FINALIZED ||
                      realTimeEvaluation?.ratingRevision?.ratingRevision ===
                        null
                        ? evaluation?.ratingRevision?.ratingRevision
                        : realTimeEvaluation?.ratingRevision?.ratingRevision ??
                          evaluation?.ratingRevision?.ratingRevision}
                    </S.ClubHoleCount>
                  </S.TableColEnd>
                  <S.TableColEnd>
                    <S.ClubHoleContainer>
                      {evaluation?.status === EvalStatus.FINALIZED ? (
                        <S.LockedHolesCount>
                          {evaluation.holeIds.length}
                        </S.LockedHolesCount>
                      ) : (
                        <>
                          <S.LockedHolesCount>
                            {lockedHoles.length}/{evaluation.holeIds.length}
                          </S.LockedHolesCount>
                        </>
                      )}
                    </S.ClubHoleContainer>
                  </S.TableColEnd>
                  <HideOnTablet>
                    <S.TableCol>
                      {(evaluation?.status === EvalStatus.FINALIZED
                        ? evaluation?.manualVersion
                        : realTimeEvaluation?.manualVersion ||
                          evaluation?.manualVersion) || '-'}
                    </S.TableCol>
                  </HideOnTablet>
                  <S.TableCol>
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        flex: 1,
                        justifyContent: 'flex-end',
                      }}
                    >
                      {evalActionsMenu}
                      {/*
                    <RatingListInfoDropdown
                      evaluation={evaluation}
                      realTimeEval={realTimeEvaluation}
                    />
                    <RoundedDeleteButton
                      style={{
                        width: '100%',
                        maxWidth: 180,
                      }}
                      title="Delete"
                      loading={isLoading}
                      onPress={() => showConfirmationModal(evaluation.id)}
                      disabled={canDelete}
                    />
                    */}
                      <RoundedButton
                        isDefault={evaluation.status !== EvalStatus.FINALIZED}
                        style={{
                          width: '100%',
                          maxWidth: 180,
                        }}
                        title={evaluationButtonText(evaluation.status)}
                        onPress={async () =>
                          await continueOrCreateRevisionClickFn(evaluation)
                        }
                      />
                      {evaluation.status === EvalStatus.FINALIZED && (
                        <RoundedButton
                          style={{
                            width: '100%',
                            maxWidth: 180,
                          }}
                          title="View"
                          onPress={async () =>
                            await viewEvaluationFn(evaluation)
                          }
                        />
                      )}
                    </div>
                    <TwoButtonModal
                      isVisible={showCreateRevisionModal}
                      title="Create Revision"
                      description="This will create a new rating based on this finalized evaluation."
                      onPressRight={async () =>
                        await continueOrCreateRevision(evaluation)
                      }
                      onPressLeft={() => setShowCreateRevisionModal(false)}
                      rightButtonText="Create Revision"
                      leftButtonText="Cancel"
                    />
                  </S.TableCol>
                </S.TableRow>
              </React.Fragment>
            )
          })}
        </tbody>

        <TwoButtonModal
          isVisible={showDeleteEvaluationConfirmModalVisible}
          title="Are you sure you want to delete this rating?"
          description="This rating is currently in progress, deleting it will remove all progress."
          onPressRight={async () => await deleteEvaluationFn(selectedEval)}
          onPressLeft={() => setShowDeleteEvaluationConfirmModalVisible(false)}
          rightButtonText="Delete"
          leftButtonText="Cancel"
          destructive
        />

        <TwoButtonModal
          isVisible={showResetEvaluationRatingRevisionConfirmModalVisible}
          title="Are you sure you want reset revision?"
          description="This will remove any info about the source evaluation and set the Revision number too 0."
          onPressRight={async () =>
            await resetEvaluationRatingRevisionFn(selectedEval)
          }
          onPressLeft={() =>
            setShowResetEvaluationRatingRevisionConfirmModalVisible(false)
          }
          rightButtonText="Yes, reset"
          leftButtonText="Cancel"
          destructive
        />
      </S.Table>
    </S.TableContainer>
  )
}

export default InProgressEvaluationsList
